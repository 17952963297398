/*
  Parent -> Child
  Husband -> Wife
*/

export const genders = {
  female: 'female',
  male: 'male',
};

export const religions = {
  muslim: 'muslim',
  christian: 'christian',
  jew: 'jew',
  other: 'other',
};

export const primaryRelations = {
  mother: 'mother',
  father: 'father',
  wife: 'wife',
  husband: 'husband',
  son: 'son',
  daughter: 'daughter',

  // basic
  parent: 'parent',
  spouse: 'spouse',
  child: 'child',
};

export const person = {
  alive: true,
  murderer: false,
  muslim: true,
  gender: '',
  _id: '',
  daughter: [],
  father: '',
  mother: '',
  name: '',
  son: [],
  wife: [],
};

export const handleIds = {
  top: '23aasds',
  topFoster: '876n5gb',
  bottom: 'asdq234',
  right: 'asd3241',
  left: 'lef324ast',
};

export const commonPerson = {
  type: 'person',
  data: {
    name: '',
    alive: true,
    religion: religions.muslim,
    murderer: false,
    illegitimate: false,
    gender: genders.male,
  },
  position: { x: 0, y: 0 },
};

export const BrowserUtilityConstants = {
  treeNodes: 'is-nodes',
  treeEdges: 'is-edges',
  treeSelectedNode: 'is-node',
};

export const languages = {
  english: 'english',
  arabic: 'arabic',
  urdu: 'urdu',
};
