import { languages } from 'utility';
import { ArabicLanguage } from './arabic';
import { EnglishLanguage } from './english';
import { UrduLanguage } from './urdu';

const languageObject = {
  [languages.arabic]: ArabicLanguage,
  [languages.english]: EnglishLanguage,
  [languages.urdu]: UrduLanguage,
};

export const adaptlang = (key, lang) => {
  return languageObject[lang][key] || key;
};
