import { BrowserUtility } from 'utility/browser-utility';
import { useDebouncedEffect } from './debounce';
import { useEffect, useState } from 'react';
import { BrowserUtilityConstants, CommonUtility } from 'utility';
import { initialNodes } from 'page-components/family-tree/common';

export const useTreeSaver = (n, e, _selectedNode) => {
  const [loading, setLoading] = useState(true);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedNode, setSelectedNode] = useState([]);

  const [saving, setSaving] = useState(false);

  useDebouncedEffect(
    () => {
      setSaving(true);
    },
    [n, e],
    500,
  );

  useDebouncedEffect(
    () => {
      BrowserUtility.saveObj(BrowserUtilityConstants.treeNodes, n);
      setSaving(false);
    },
    [n],
    2000,
  );

  useDebouncedEffect(
    () => {
      BrowserUtility.saveObj(BrowserUtilityConstants.treeSelectedNode, _selectedNode);
      setSaving(false);
    },
    [_selectedNode],
    200,
  );

  useDebouncedEffect(
    () => {
      BrowserUtility.saveObj(BrowserUtilityConstants.treeEdges, e);
      setSaving(false);
    },
    [e],
    200,
  );

  useEffect(() => {
    try {
      const edges = BrowserUtility.getObj(BrowserUtilityConstants.treeEdges);
      const selectedNode = BrowserUtility.getObj(
        BrowserUtilityConstants.treeSelectedNode,
      );
      let nodes =
        BrowserUtility.getObj(BrowserUtilityConstants.treeNodes) || initialNodes;
      if (!CommonUtility.isValidArray(nodes)) {
        nodes = initialNodes;
      }
      setEdges(edges || []);
      setNodes(nodes || []);
      setSelectedNode(selectedNode || '1');
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }, [initialNodes]);

  return { nodes: nodes, edges: edges, loading, saving, selectedNode };
};
