import React, { useMemo, useRef, useState } from 'react';
import {
  EdgeLabelRenderer,
  BaseEdge,
  getSmoothStepPath,
  useNodes,
  useEdges,
} from '@xyflow/react';
import styled from 'styled-components';
import { genders, primaryRelations } from 'utility';
import { BlackButton } from 'elements';
import { Minus, Plus } from '@phosphor-icons/react';
import { Button, InputNumber } from 'antd';
import { generateChildNode } from './EdgeUtility';
import { useClickOutside } from 'hooks';
import { RelationUtility } from './RelationUtility/common';
import toast from 'react-hot-toast';
import { useTreeContext } from 'hooks/treeContext';
import { lineColors } from '../common';
import { useGlobal } from 'context.js';

const StyledEdgeLabel = styled.div`
  position: absolute;
  font-size: 11px;
  font-weight: bold;
  color: grey;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  padding: 0 2px;
`;

function EdgeLabel({ transform, label }) {
  return (
    <StyledEdgeLabel
      style={{
        transform,
      }}
      className="nodrag nopan"
    >
      {label}
    </StyledEdgeLabel>
  );
}

const AddChildButton = styled(BlackButton)`
  pointer-events: all;
  background: grey;
  &:hover {
    background-color: black;
  }
`;

const SpouseRelation = ({
  addChild,
  targetX,
  targetY,
  sourceX,
  sourceY,
  toggleDivorced,
  divorced,
  removeEdge,
}) => {
  const [addClicked, setAddClicked] = useState(false);
  const [nOfChildren, setnOfChildren] = useState(1);
  const { langAdapt } = useGlobal();

  const ref = useRef(null);

  useClickOutside(ref, () => {
    setAddClicked(false);
  });

  const setNumber = num => {
    setnOfChildren(num);
  };

  const midX = useMemo(() => (sourceX + targetX) / 2, [sourceX, targetX]);
  const midY = useMemo(() => (sourceY + targetY) / 2, [sourceY, targetY]);

  const onClick = gender => {
    addChild(nOfChildren, gender);
    setAddClicked(false);
  };

  return (
    <div
      style={{
        transform: `translate(-50%, -50%) translate(${midX}px, ${midY}px)`,
        pointerEvents: 'all',
        position: 'absolute',
      }}
      className="nopan d-flex flex-column align-items-center"
    >
      <AddChildButton shape="round" onClick={() => toggleDivorced(divorced)}>
        {divorced ? langAdapt('markMarried') : langAdapt('markDivorced')}
      </AddChildButton>
      <DeteleBtn
        type="primary"
        danger
        onClick={removeEdge}
        shape="circle"
        className="my-2"
      >
        <Minus weight="bold" />
      </DeteleBtn>

      {addClicked ? (
        <div ref={ref} className="d-flex flex-column align-items-center">
          <InputNumber max={10} min={1} onChange={setNumber} value={nOfChildren} />
          <div className="d-flex">
            <BlackButton onClick={() => onClick(genders.male)} type="primary mt-2">
              <Plus weight="bold" />
              {nOfChildren} {langAdapt('Son')}
            </BlackButton>
            <BlackButton
              onClick={() => onClick(genders.female)}
              className="ml-2 mt-2"
              type="primary"
            >
              <Plus weight="bold" />
              {nOfChildren} {langAdapt('Daughter')}
            </BlackButton>
          </div>
        </div>
      ) : (
        <AddChildButton onClick={() => setAddClicked(true)}>
          <Plus />
          {langAdapt('Child')}
        </AddChildButton>
      )}
    </div>
  );
};

const ParentRelation = ({
  targetX,
  targetY,
  sourceX,
  sourceY,
  toggleFoster,
  removeEdge,
  foster,
  isMother,
}) => {
  const midX = useMemo(() => (sourceX + targetX) / 2, [sourceX, targetX]);
  const midY = useMemo(() => (sourceY + targetY) / 2, [sourceY, targetY]);

  const { langAdapt } = useGlobal();

  return (
    <div
      style={{
        transform: `translate(-50%, -50%) translate(${midX}px, ${midY}px)`,
        pointerEvents: 'all',
        position: 'absolute',
      }}
      className="nopan d-flex flex-column align-items-center"
    >
      {isMother && (
        <AddChildButton shape="round" onClick={() => toggleFoster(foster)}>
          {foster ? langAdapt('markReal') : langAdapt('markFoster')}
        </AddChildButton>
      )}
      <DeteleBtn
        type="primary"
        danger
        onClick={removeEdge}
        shape="circle"
        className="my-2"
      >
        <Minus weight="bold" />
      </DeteleBtn>
    </div>
  );
};

const DeteleBtn = styled(Button)`
  pointer-events: all !important;

  color: white;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 25px !important;
  min-height: 25px !important;
  max-width: 25px !important;
  max-height: 25px !important;
  padding: 10px;
`;

export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  source,
  target,
}) => {
  const nodes = useNodes();
  const edges = useEdges();

  const { activeEdge } = useTreeContext();
  const { langAdapt } = useGlobal();

  const isEdgeActive = activeEdge === id;

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const color = useMemo(() => {
    if (data.relation === primaryRelations.spouse) {
      const husband = nodes.find(x => x.id === source);
      const wife = nodes.find(x => x.id === target);
      if (husband.data.alive && wife.data.alive) {
        return lineColors.spouse;
      }
      return lineColors.grey;
    }
    if (data.relation === primaryRelations.parent) {
      const parentGender = nodes.find(x => x.id === source)?.data.gender;
      const childGender = nodes.find(x => x.id === target)?.data.gender;

      if (parentGender === genders.male) {
        return childGender === genders.male
          ? lineColors.fatherSon
          : lineColors.fatherDaughter;
      }

      if (parentGender === genders.female) {
        return childGender === genders.male
          ? lineColors.motherSon
          : lineColors.motherDaughter;
      }
    }
  }, [data, source, target, nodes]);

  const addChild = (nOfChildren, childGender) => {
    let children = [];
    for (let i = 0; i < nOfChildren; i++) {
      children.push(generateChildNode(childGender));
    }

    data.addCommonChild({ ...data, target, source }, children, 'bottom');
  };

  const toggleDivorced = divorced => {
    const sourceNode = nodes.find(x => x.id === source);
    const targetNode = nodes.find(x => x.id === target);

    if (divorced) {
      if (!RelationUtility.hasSpouseLimit(source, sourceNode.data.gender, nodes, edges)) {
        toast.error(`${sourceNode.data.name} cannot have more spouse(s)`);
        return;
      } else if (
        !RelationUtility.hasSpouseLimit(target, targetNode.data.gender, nodes, edges)
      ) {
        toast.error(`${targetNode.data.name} cannot have more spouse(s)`);
        return;
      }
    }

    data.toggleDivorce(id, divorced);
  };

  const toggleFoster = foster => {
    const motherOfChild = RelationUtility.getMother(target, nodes, edges);

    if (foster && motherOfChild) {
      toast.error('Can only have 1 Real Mother');
      return;
    }

    data.toggleFoster(id, foster);
  };

  const removeEdge = () => {
    data.removeEdge(id);
  };

  return (
    <>
      <BaseEdge
        style={{
          stroke: color,
          strokeWidth: 3,
          opacity: 0.7,
        }}
        id={id}
        path={edgePath}
      />
      <EdgeLabelRenderer>
        {data.startLabel && (
          <EdgeLabel
            transform={`translate(-50%, 0%) translate(${sourceX + data.startPadding}px,${
              sourceY + data.topPadding
            }px)`}
            label={langAdapt(data.startLabel)}
          />
        )}
        {isEdgeActive && (
          <>
            {data.relation === primaryRelations.parent && (
              <ParentRelation
                targetX={targetX}
                targetY={targetY}
                sourceX={sourceX}
                removeEdge={removeEdge}
                sourceY={sourceY}
                id={id}
                key={id}
                toggleFoster={toggleFoster}
                foster={!!data.foster}
                isMother={data.startLabel === 'Mother'}
              />
            )}
            {data.relation === primaryRelations.spouse && (
              <SpouseRelation
                addChild={addChild}
                targetX={targetX}
                targetY={targetY}
                sourceX={sourceX}
                removeEdge={removeEdge}
                sourceY={sourceY}
                id={id}
                key={id}
                toggleDivorced={toggleDivorced}
                divorced={!!data.divorced}
              />
            )}
          </>
        )}

        {data.endLabel && (
          <EdgeLabel
            transform={`translate(-50%, -100%) translate(${targetX - data.endPadding}px,${
              targetY - data.bottomPadding
            }px)`}
            label={langAdapt(data.endLabel)}
          />
        )}
      </EdgeLabelRenderer>
    </>
  );
};
