import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { InheritanceDataViewer } from './Profile';
import { AllHandles } from './Handle';
import { Toolbars } from './Toolbar';
import { primaryRelations } from 'utility';
import { useEdges, useNodes } from '@xyflow/react';
import { RelationUtility } from './RelationUtility/common';
import { useDebouncedMemo } from 'hooks';

const NodeContainer = styled.div`
  border-radius: 4px;
  width: 190px;
  height: 97px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;

export const InheritanceNode = memo(({ data, id, positionAbsoluteY }) => {
  const edges = useEdges();
  const nodes = useNodes();

  const relatedEdges = useMemo(
    () => edges.filter(x => [x.source, x.target].includes(id)),
    [edges, id],
  );

  const canHaveSpouse = useDebouncedMemo(
    () => RelationUtility.hasSpouseLimit(id, data.gender, nodes, edges),
    [edges, data.gender, nodes],
    300,
  );

  const { canHaveParents, canHaveMother, canHaveFather } = useMemo(() => {
    const parents = relatedEdges.filter(
      x => x.data.relation === primaryRelations.parent && x.target === id,
    );

    if (parents.length >= 2) {
      return {
        canHaveFather: false,
        canHaveMother: false,
        canHaveParents: false,
      };
    }

    if (parents.length === 1) {
      return {
        canHaveFather: parents[0]?.data.startLabel !== 'Father',
        canHaveMother: parents[0]?.data.startLabel === 'Father',
        canHaveParents: true,
      };
    }

    return {
      canHaveFather: true,
      canHaveMother: true,
      canHaveParents: true,
    };
  }, [relatedEdges, id]);

  return (
    <NodeContainer>
      <InheritanceDataViewer
        hasMother={!canHaveMother}
        hasFather={!canHaveFather}
        data={data}
        id={id}
      />
      <Toolbars
        posY={positionAbsoluteY}
        id={id}
        data={data}
        gender={data.gender}
        canHaveFather={canHaveFather}
        canHaveMother={canHaveMother}
        canHaveParents={canHaveParents}
        canHaveSpouse={canHaveSpouse}
      />
      <AllHandles canHaveParents={canHaveParents} canHaveSpouse={canHaveSpouse} />
    </NodeContainer>
  );
});
