export const Images = {
  inheritance: {
    profile: {
      male: require('./inheritance/profile/male.png'),
      female: require('./inheritance/profile/female.png'),
    },
  },
  landing: {
    screen1: require('./landing/screen1.png'),
    screen2: require('./landing/screen2.png'),
  },
};
