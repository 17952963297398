const { useEffect } = require('react');

export const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const handleClick = event => {
      // Check if the click is outside the element referenced by `ref`
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick); // Handle touch devices

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [ref, handler]); // Dependency array
};
