import { PencilSimple } from '@phosphor-icons/react';
import { Tag, Tooltip } from 'antd';
import { Images } from 'images';
import styled from 'styled-components';
import { CommonUtility, genders, religions, themeColors } from 'utility';
import { useTreeContext } from 'hooks/treeContext';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useGlobal } from 'context.js';

const ProfileTopSection = styled.div`
  border-radius: 3px 3px 0 0;
  padding: 0 7px;
  padding-top: 7px;
  border-bottom: 1px solid rgb(245, 245, 245);
  .name-container {
    margin-left: 10px;
    text-overflow: ellipsis;
    width: 125px;
    max-width: 125px;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 5px;
  }

  .relation-node {
    width: 135px;
    max-width: 135px;
  }

  .gender {
    font-size: 12px;
    text-transform: capitalize;
    font-style: italic;
  }
`;

const DataContainer = styled.div`
  position: relative;
  border-radius: 6px;
  .not-eligible {
    pointer-events: none;
  }

  &:hover {
    .dark-bg {
      opacity: 0;
      pointer-events: none;
    }
  }

  border: 0px;
  transition: all 0.1s;
  margin: 0px;

  .inner-container {
    background: white;
    border-radius: 5px;
    transition: all 0.1s;
  }

  &.node-active {
    .inner-container {
      margin: 2px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -100px;
      left: -50px;
      width: 300px;
      height: 300px;
      background: conic-gradient(
        black 20deg,
        #59cdff 50deg,
        black 80deg,
        black 200deg,
        #59cdff 230deg,
        black 260deg
      );
      animation: rotateGradient 7s linear infinite;
      z-index: -1;
    }
    overflow: hidden;
  }

  @keyframes rotateGradient {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const NotEligible = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: ease 0.2s;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 10;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
`;

const InheritanceFlag = styled.div`
  background: ${({ theme }) => theme.darkGreen};
  color: white;
  border-radius: 3px;
  padding: 4px 6px;
  font-size: 12px;
  max-width: fit-content;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.3);
`;

const ProfileButton = styled.div`
  .details {
    display: none;
  }
  &:hover {
    .details {
      display: flex;
    }
  }
`;

const InfoButton = styled.div`
  background: ${({ theme }) => theme.darkGreen};
  color: white;
  font-size: 12px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 3px;
  row-gap: 3px;
  .ant-tag {
    margin: 0;
  }
`;

const Image = styled.img`
  width: 34px;
  height: 34px;
  object-fit: contain;
  margin-top: 6px;
  margin-left: 3px;
`;

const ImageContainer = styled.div`
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: ${({ bgColor }) => bgColor};
  margin-bottom: 5px;
`;

const ProfileContianer = styled.div`
  position: relative;
`;
const StatusBadge = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ bgColor }) => bgColor};
  top: 0;
  left: 0;
`;

const ParentLabelContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -16px;
  font-size: 11px;
  color: grey;
  font-style: italic;
`;

const NoParentLabel = ({ hasMother, hasFather }) => {
  const { langAdapt } = useGlobal();

  if (hasFather && hasMother) return <></>;

  if (!hasFather && !hasMother)
    return <ParentLabelContainer>{langAdapt('noParents')}</ParentLabelContainer>;

  return (
    <ParentLabelContainer>
      {hasMother ? langAdapt('noFather') : langAdapt('noMother')}
    </ParentLabelContainer>
  );
};

export const RelationDataViewer = ({ data, id, hasMother, hasFather }) => {
  const { setActiveNode, islamicRelations, selectedNode, setSelectedNode } =
    useTreeContext();

  const { langAdapt } = useGlobal();

  const isCurrentActive = selectedNode === id;

  const islamicRelation = islamicRelations[id];

  const selectNode = () => {
    setSelectedNode(id);
  };

  return (
    <DataContainer
      onDoubleClick={selectNode}
      className={isCurrentActive ? 'node-active' : ''}
    >
      <div className="inner-container">
        <NoParentLabel hasFather={hasFather} hasMother={hasMother} />
        <ProfileTopSection className="d-flex align-items-center">
          <Profile alive={data.alive} isMale={data.gender === genders.male} />
          <div className="name-container relation-node">
            <span className="text">{data.name}</span>
            <div className="d-flex align-items-center ">
              <div className="gender">{langAdapt(data.gender)}</div>
              {islamicRelation && (
                <Tag
                  bordered={false}
                  className="ml-1"
                  color={islamicRelation.mehram ? 'purple' : 'red'}
                >
                  {islamicRelation.mehram ? langAdapt('mehram') : langAdapt('nonMehram')}
                </Tag>
              )}
            </div>
          </div>
        </ProfileTopSection>

        <ProfileButton className="d-flex p-2 justify-content-between align-items-end">
          <BadgesContainer>
            <Tag bordered={false} color={'black'}>
              {langAdapt(data.religion)}
            </Tag>
            {islamicRelation && !islamicRelation.mehram && (
              <Tag bordered={false} color={islamicRelation.nikkah ? 'magenta' : 'blue'}>
                {islamicRelation.nikkah
                  ? langAdapt('nikkahable')
                  : langAdapt('nonNikkahable')}
              </Tag>
            )}
          </BadgesContainer>
          <InfoButton onClick={e => setActiveNode(id)}>
            <PencilSimple weight="fill" />
          </InfoButton>
        </ProfileButton>
      </div>
    </DataContainer>
  );
};

const FractionContainer = styled.div``;

const Fraction = ({ numerator, denominator, extraFraction, remainingPercentage = 0 }) => {
  if (extraFraction === 1) {
    const percentage = (numerator / denominator) * 100;
    return (
      <FractionContainer>
        {CommonUtility.roundNumber(percentage + remainingPercentage)}
        <span>%</span>
      </FractionContainer>
    );
  }

  const res = numerator / denominator;
  const remainingFraction = extraFraction * 24;

  const percentage = ((remainingFraction * res) / 24) * 100;

  return (
    <FractionContainer>
      {CommonUtility.roundNumber(percentage + remainingPercentage)}
      <span>%</span>
    </FractionContainer>
  );
};

const Ratio = ({ ratio, fixedShares, totalRatio }) => {
  const remaining = 1 - fixedShares / 24;

  const inherit = ratio / totalRatio;

  return (
    <FractionContainer>
      {CommonUtility.roundNumber(remaining * inherit * 100)}
      <span>%</span>
    </FractionContainer>
  );
};

export const InheritanceDataViewer = ({ data, id, hasMother, hasFather }) => {
  const { setActiveNode, shares, shareData, selectedNode, setSelectedNode } =
    useTreeContext();

  const { langAdapt } = useGlobal();

  const isCurrentActive = selectedNode === id;

  const personInherit = shares?.[id];

  const { denominator, extraFraction } = useMemo(() => {
    let denom = 24;
    let extraFraction = 1;

    let totalShares = shareData?.totalShares + shareData?.spouseShare;

    if (shareData?.hasResidue) {
      if (totalShares > 24) {
        denom = totalShares;
      }
    } else {
      if (denom > totalShares) {
        if (personInherit?.spouse) {
          if (shareData.totalShares) {
            denom = 24;
          } else {
            denom = shareData.spouseShare;
          }
        } else {
          denom = shareData?.totalShares;
          extraFraction = (24 - shareData?.spouseShare) / 24;
        }
      } else {
        denom = totalShares;
      }
    }
    return { denominator: denom, extraFraction };
  }, [shareData, personInherit]);

  const inherit = useMemo(() => {
    if (!personInherit || !CommonUtility.isValidObject(shareData)) {
      return 0;
    }

    if (personInherit.share && !personInherit.residuary) {
      return shareData.amount * (personInherit.share / denominator);
    }

    let totalShares = shareData.totalShares + shareData.spouseShare;

    if (totalShares >= 24) {
      return 0;
    }

    const remaining = shareData.amount - shareData.amount * (totalShares / 24);

    if (personInherit.residuary) {
      if (personInherit.getRemaining) {
        if (personInherit.share) {
          return remaining + shareData.amount * (personInherit.share / denominator);
        }
        return remaining;
      } else if (personInherit.ratio) {
        return (personInherit.ratio / (shareData.totalRatio || 1)) * remaining;
      }
    }
  }, [personInherit, shareData, denominator]);

  const remainingPercentage = useMemo(() => {
    if (
      personInherit?.getRemaining &&
      personInherit?.share &&
      CommonUtility.isValidObject(shareData)
    ) {
      let totalShares = shareData.totalShares + shareData.spouseShare;
      const remaining = ((24 - totalShares) * 100) / 24;
      return remaining;
    }
    return 0;
  }, [personInherit, shareData]);

  const selectNode = () => {
    if (data.religion !== religions.muslim) {
      toast.error('Sorry this app is not optimised for non-muslims');
      return;
    }
    setSelectedNode(id);
  };

  const isEligible =
    data?.religion === religions.muslim &&
    data.alive &&
    !data.murderer &&
    !data.illegitimate;

  return (
    <DataContainer
      onDoubleClick={selectNode}
      className={isCurrentActive ? 'node-active' : ''}
    >
      <div className="inner-container">
        {!isEligible && <NotEligible className="dark-bg" />}
        {!!inherit && personInherit && (
          <InheritanceFlag>
            <div className="d-flex flex-column">
              <div className="d-flex">
                <span>{langAdapt('amount')}</span>
                <span className="ml-1">
                  {CommonUtility.currencyFormat(inherit * extraFraction)}
                </span>
              </div>
              <div className="d-flex">
                <span className="mr-1">{langAdapt('share')}</span>
                {personInherit.ratio ? (
                  <Ratio
                    fixedShares={shareData.totalShares + shareData.spouseShare}
                    ratio={personInherit.ratio}
                    totalRatio={shareData.totalRatio}
                  />
                ) : (
                  <Fraction
                    remainingPercentage={remainingPercentage}
                    extraFraction={extraFraction}
                    numerator={personInherit.share}
                    denominator={denominator}
                  />
                )}
              </div>
            </div>
          </InheritanceFlag>
        )}
        <NoParentLabel hasFather={hasFather} hasMother={hasMother} />
        <ProfileTopSection className="d-flex align-items-center">
          <Profile alive={data.alive} isMale={data.gender === genders.male} />
          <div className="name-container">
            <span className="text">{data.name}</span>
            <div className="gender">{langAdapt(data.gender)}</div>
          </div>
        </ProfileTopSection>

        <ProfileButton className="d-flex p-2 justify-content-between align-items-end">
          <BadgesContainer>
            <Tag color={'black'}>{langAdapt(data.religion)} </Tag>
            {data.murderer && <Tag color="red">{langAdapt('murderer')}</Tag>}
            {data.illegitimate && <Tag color="red">{langAdapt('illegitimate')}</Tag>}
          </BadgesContainer>
          <InfoButton onClick={e => setActiveNode(id)}>
            <PencilSimple weight="fill" />
          </InfoButton>
        </ProfileButton>
      </div>
    </DataContainer>
  );
};

const Profile = ({ isMale, alive, showAliveBadge = true }) => {
  return (
    <ProfileContianer>
      {showAliveBadge && (
        <Tooltip title={alive ? 'Alive' : 'Not Alive'}>
          <StatusBadge bgColor={alive ? 'lightgreen' : 'red'} />
        </Tooltip>
      )}
      <ImageContainer bgColor={isMale ? themeColors.darkGreen : 'rgb(170,170,170)'}>
        <Image
          src={
            isMale ? Images.inheritance.profile.male : Images.inheritance.profile.female
          }
        />
      </ImageContainer>
    </ProfileContianer>
  );
};
