import { Heart, HandCoins } from '@phosphor-icons/react';
import { useGlobal } from 'context.js';
import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { langAdapt } = useGlobal();

  const toggleSidebar = open => {
    setIsCollapsed(open);
  };

  const menus = [
    {
      text: langAdapt('inhertiance'),
      key: 'inheritance',
      path: '/app/inheritance',
      parentLink: '/app/inheritance',
      icon: <HandCoins className="sidebar-icon-main" />,
    },
    {
      text: langAdapt('relation'),
      key: 'relation',
      path: '/app/relation',
      parentLink: '/app/relation',
      icon: <Heart className="sidebar-icon-main" />,
    },
  ];

  return (
    <SidebarContext.Provider
      value={{
        menus,
        setIsCollapsed,
        isCollapsed,
        toggleSidebar,
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export const useSidebar = () => useContext(SidebarContext);
