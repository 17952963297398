import { CommonUtility, genders, primaryRelations } from 'utility';
import { RelationUtility } from './common';

/*
  1. Father-children and their lineage
  2. Mother-children and their lineage
  3. Own Lineage
  4. Mother Sibling from GrandFather/GrandMother
  5. Father Sibling from GrandFather/GrandMother
  6. Is not their 1,2,3,4,5

  Not Spouse
  1. Father-children and their lineage
  2. Mother-children and their lineage
  3. Own Lineage
  4. Mother Sibling from GrandFather/GrandMother
  5. Father Sibling from GrandFather/GrandMother
  6. Is not their 1,2,3,4,5
*/

export const checkMehram = (
  personFromId,
  personToId,
  relationsList,
  personList,
  checkSameGender = true,
) => {
  const personFrom = personList.find(x => x.id === personFromId);
  const personTo = personList.find(x => x.id === personToId);

  if (!personFrom || !personTo) {
    return {
      mehram: false,
      nikkah: false,
    };
  }

  if (personFrom.data.gender === personTo.data.gender && checkSameGender) {
    return {
      mehram: true,
      nikkah: false,
    };
  }

  // Person To is not in Person From Lineage
  if (checkPerson1InOtherRelations(personFromId, personToId, relationsList, personList)) {
    return {
      mehram: true,
      nikkah: false,
    };
  }

  // Person From is not in Person To Lineage
  if (checkPerson1InOtherRelations(personToId, personFromId, relationsList, personList)) {
    return {
      mehram: true,
      nikkah: false,
    };
  }

  // To-do living/divorced spouse
  // can marry spouse sibling and there lineage but not spouse's mother and spouse's lineage

  const personToSpouseRelative = checkPerson1SpouseInOtherRelations(
    personToId,
    personFromId,
    relationsList,
    personList,
  );

  if (personToSpouseRelative) {
    return CommonUtility.isValidObject(personToSpouseRelative)
      ? personToSpouseRelative
      : {
          mehram: false,
          nikkah: false,
        };
  }

  const personFromSpouseRelative = checkPerson1SpouseInOtherRelations(
    personFromId,
    personToId,
    relationsList,
    personList,
  );

  if (personFromSpouseRelative) {
    return CommonUtility.isValidObject(personFromSpouseRelative)
      ? personFromSpouseRelative
      : {
          mehram: false,
          nikkah: false,
        };
  }

  const canInterMarry = RelationUtility.religionsAcceptance(personFrom, personTo);

  return {
    mehram: false,
    nikkah: canInterMarry ? true : false,
  };
};

const checkPerson1SpouseInOtherRelations = (
  personFromId,
  personToId,
  relationsList,
  personList,
) => {
  let spouses = [];
  const personFrom = personList.find(x => x.id === personFromId);

  if (personFrom.data.gender === genders.male) {
    spouses = RelationUtility.getWives(personFromId, relationsList);
  } else {
    spouses = RelationUtility.getHusband(personFromId, relationsList);
  }

  for (const spouse of spouses) {
    const relation = relationsList.find(
      x =>
        [x.target, x.source].includes(spouse) &&
        [x.target, x.source].includes(personFromId) &&
        x.data.relation === primaryRelations.spouse,
    );
    const spouseData = personList.find(x => x.id === spouse);
    if (!spouseData?.data?.alive || relation?.data?.divorced) {
      if (spouse === personToId) {
        return {
          mehram: false,
          nikkah: false,
        };
      }
      // only check lineage
      const spouseInPersonLineage = isPerson1inPerson2Lineage(
        spouse,
        personToId,
        relationsList,
        personList,
        false,
        true,
      );
      if (spouseInPersonLineage) {
        return CommonUtility.isValidObject(spouseInPersonLineage)
          ? spouseInPersonLineage
          : {
              mehram: true,
              nikkah: false,
            };
      }

      const personInSpouseLineage = isPerson1inPerson2Lineage(
        personToId,
        spouse,
        relationsList,
        personList,
        false,
        true,
      );
      if (personInSpouseLineage) {
        return CommonUtility.isValidObject(personInSpouseLineage)
          ? personInSpouseLineage
          : {
              mehram: true,
              nikkah: false,
            };
      }
    } else {
      if (spouse === personToId) {
        return {
          mehram: true,
          nikkah: false,
        };
      }

      // Person To is not in Spouse Lineage
      const spouseIsRelatedTo1 = checkPerson1InOtherRelations(
        personToId,
        spouse,
        relationsList,
        personList,
        true,
      );
      if (spouseIsRelatedTo1) {
        return spouseIsRelatedTo1;
      }

      // Spouse is not in person To Lineage
      const person1IsRelatedToS = checkPerson1InOtherRelations(
        spouse,
        personToId,
        relationsList,
        personList,
        true,
      );
      if (person1IsRelatedToS) {
        return person1IsRelatedToS;
      }
    }
  }
  return false;
};

const checkPerson1InOtherRelations = (
  person1,
  person2,
  relationList,
  personList,
  spouseContext = false,
) => {
  const parentsOfFrom = RelationUtility.getAllParents(person2, relationList);

  for (const parent of parentsOfFrom) {
    // case - 1 Paternal Siblings and their children
    // case - 2 Maternal Siblings and their children
    if (isPerson1inPerson2Lineage(person1, parent, relationList, personList)) {
      if (spouseContext) {
        return {
          mehram: false,
          nikkah: false,
        };
      }
      return true;
    }
  }
  // case - 3 Own Children
  const ownChildren = isPerson1inPerson2Lineage(
    person1,
    person2,
    relationList,
    personList,
    false,
    spouseContext,
  );
  if (ownChildren) {
    if (spouseContext) {
      return CommonUtility.isValidObject(ownChildren)
        ? ownChildren
        : {
            mehram: true,
            nikkah: false,
          };
    }
    return true;
  }

  // case-4 Paternal uncles and aunties
  // case-5 Maternal uncles and aunties
  for (const parent of parentsOfFrom) {
    const parentsParent = RelationUtility.getParents(parent, relationList);
    for (const grandParent of parentsParent) {
      const unclesAunties = RelationUtility.getChildrens(grandParent, relationList);
      if (unclesAunties.includes(person1)) {
        if (spouseContext) {
          return {
            mehram: false,
            nikkah: false,
          };
        }
        return true;
      }
    }
  }

  return false;
};

const isPerson1inPerson2Lineage = (
  person1Id,
  person2Id,
  relationList,
  personList,
  canMarryUnMarriedSpouse = true,
  spouseContext,
) => {
  const children = RelationUtility.getAllChildrens(person2Id, relationList);

  if (!canMarryUnMarriedSpouse) {
    const person2 = personList.find(x => x.id === person2Id);
    if (person2) {
      let spouses = [];
      let relatedSpouse = null;
      if (spouseContext) {
        if (person2.data.gender === genders.male) {
          spouses = RelationUtility.getWivesRelation(person2Id, relationList);
          relatedSpouse = spouses.find(x => x.target === person1Id);
        } else {
          spouses = RelationUtility.getHusbandRelation(person2Id, relationList);
          relatedSpouse = spouses.find(x => x.source === person1Id);
        }

        if (relatedSpouse) {
          return {
            mehram: false,
            nikkah: relatedSpouse?.data?.divorced ? true : false,
          };
        }
      } else {
        if (person2.data.gender === genders.male) {
          spouses = RelationUtility.getWives(person2Id, relationList);
        } else {
          spouses = RelationUtility.getHusband(person2Id, relationList);
        }
        if (spouses.includes(person1Id)) {
          return true;
        }
      }
    }
  }

  if (!CommonUtility.isValidArray(children)) return false;

  for (const child of children) {
    if (child === person1Id) {
      return true;
    }
  }

  for (const child of children) {
    const inLineage = isPerson1inPerson2Lineage(
      person1Id,
      child,
      relationList,
      personList,
      canMarryUnMarriedSpouse,
      spouseContext,
    );
    if (inLineage) {
      return inLineage;
    }
  }
};
