export class BrowserUtility {
  static save = (key, value) => {
    window.localStorage.setItem(key, value);
  };

  static get = key => typeof window !== 'undefined' && window.localStorage.getItem(key);

  static saveObj = (key, obj) => {
    window.localStorage.setItem(key, JSON.stringify(obj));
  };

  static getObj = key => {
    const temp = typeof window !== 'undefined' && window.localStorage.getItem(key);
    if (temp) {
      return JSON.parse(temp);
    }
    return null;
  };

  static remove = key => {
    window.localStorage.removeItem(key);
  };

  static removeAll = () => {
    window.localStorage.clear();
  };

  static isMobileSafari() {
    const ua = window.navigator.userAgent;
    return /(iPad|iPhone|iPod).*WebKit/.test(ua) && !/(CriOS|OPiOS)/.test(ua);
  }

  static saveSession = (key, value) => {
    window.sessionStorage.setItem(key, value);
  };

  static getSession = key =>
    typeof window !== 'undefined' && window.sessionStorage.getItem(key);
}
