import {
  commonPerson,
  CommonUtility,
  genders,
  handleIds,
  primaryRelations,
} from 'utility';
import { canBeParent, canBeSpouse } from './RelationUtility';
import { canBeFoster } from './RelationUtility/Foster';

export const generateSpouseEdge = (source, target, gender, pos) => {
  const spouseHandle = {
    id: CommonUtility.generateRandomId(),
    source,
    target,
    type: 'custom',
    sourceHandle: handleIds.right,
    targetHandle: handleIds.left,
    data: {
      relation: primaryRelations.spouse,
      startLabel: 'Husband',
      endLabel: 'Wife',
      startPadding: pos === 'left' ? -30 : 30,
      endPadding: pos === 'left' ? -30 : 30,
      topPadding: 5,
      bottomPadding: 5,
    },
  };

  if (pos === 'left') {
    spouseHandle.sourceHandle = handleIds.left;
    spouseHandle.targetHandle = handleIds.right;
  }

  if (gender === genders.female) {
    spouseHandle.target = source;
    spouseHandle.source = target;
    spouseHandle.sourceHandle = handleIds.left;
    spouseHandle.targetHandle = handleIds.right;

    spouseHandle.data.startPadding = -30;
    spouseHandle.data.endPadding = -30;

    if (pos === 'left') {
      spouseHandle.data.startPadding = 30;
      spouseHandle.data.endPadding = 30;
      spouseHandle.sourceHandle = handleIds.right;
      spouseHandle.targetHandle = handleIds.left;
    }
  }

  return spouseHandle;
};

export const generateParentEdge = (
  parentId,
  childId,
  childGender,
  parentGender,
  foster = false,
) => ({
  id: CommonUtility.generateRandomId(),
  source: parentId,
  target: childId,
  sourceHandle: handleIds.bottom,
  targetHandle: foster ? handleIds.topFoster : handleIds.top,
  animated: foster ? true : false,
  type: 'custom',
  data: {
    foster,
    relation: primaryRelations.parent,
    startLabel:
      parentGender === genders.male ? 'Father' : `${foster ? 'Foster ' : ''}Mother`,
    endLabel: childGender === genders.male ? 'Son' : 'Daughter',
    startPadding: 30,
    topPadding: 10,
    bottomPadding: 10,
    endPadding: childGender === genders.male ? 20 : 30,
  },
});

export const generateSpouseNode = gender => ({
  ...commonPerson,
  id: CommonUtility.generateRandomId(),
  data: {
    ...commonPerson.data,
    name: `${
      gender === genders.male ? 'Wife' : 'Husband'
    } #${CommonUtility.getRandomNumber(3)}`,
    gender: gender === genders.male ? genders.female : genders.male,
  },
});

export const generateParentNode = parentGender => ({
  ...commonPerson,
  id: CommonUtility.generateRandomId(),
  data: {
    ...commonPerson.data,
    name: `${
      parentGender === genders.male ? 'Father' : 'Mother'
    } #${CommonUtility.getRandomNumber(3)}`,
    gender: parentGender,
  },
});

export const generateChildNode = childGender => ({
  ...commonPerson,
  id: CommonUtility.generateRandomId(),
  data: {
    ...commonPerson.data,
    name: `${
      childGender === genders.male ? 'Son' : 'Daughter'
    } #${CommonUtility.getRandomNumber(3)}`,
    gender: childGender,
  },
});

export const generatePosition = (id, oldNodes, relation, newNodePos = null) => {
  const oldNode = oldNodes.find(x => x.id === id);

  if (relation === primaryRelations.parent) {
    if (newNodePos === 'bottom') {
      return {
        x: oldNode.position.x + 20,
        y: oldNode.position.y + 160,
      };
    }
    return {
      x: oldNode.position.x + 20,
      y: oldNode.position.y - 160,
    };
  }

  if (relation === primaryRelations.spouse && newNodePos === 'left') {
    return {
      x: oldNode.position.x - 400,
      y: oldNode.position.y,
    };
  }

  return {
    x: oldNode.position.x + 400,
    y: oldNode.position.y,
  };
};

export const getValidEdge = (intentEdge, nodes = [], edges = []) => {
  const { source, target, sourceHandle, targetHandle } = intentEdge;
  const sourceNode = nodes.find(x => x.id === source);
  const targetNode = nodes.find(x => x.id === target);

  const isSourceAlone = !edges.some(
    x => [x.source, x.target].includes(source) && !x.data.foster,
  );
  const isTargetAlone = !edges.some(
    x => [x.source, x.target].includes(target) && !x.data.foster,
  );

  if (sourceHandle === handleIds.top) {
    if (canBeParent(source, target, edges, nodes, true, isTargetAlone, isSourceAlone)) {
      return generateParentEdge(
        target,
        source,
        sourceNode.data.gender,
        targetNode.data.gender,
      );
    }
    return null;
  } else if (sourceHandle === handleIds.bottom) {
    if (canBeParent(target, source, edges, nodes, true, isSourceAlone, isTargetAlone)) {
      return generateParentEdge(
        source,
        target,
        targetNode.data.gender,
        sourceNode.data.gender,
      );
    }
    return null;
  } else if (sourceHandle === handleIds.topFoster) {
    if (canBeFoster(target, source, edges, nodes)) {
      return generateParentEdge(
        target,
        source,
        sourceNode.data.gender,
        targetNode.data.gender,
        true,
      );
    }
    return null;
  } else {
    if (canBeSpouse(source, target, edges, nodes, true)) {
      return generateSpouseEdge(
        source,
        target,
        sourceNode.data.gender,
        sourceHandle === handleIds.left ? 'left' : 'right',
      );
    }
    return null;
  }
};
