import { Helmet } from 'react-helmet';

export const DefaultMetaTags = () => (
  <Helmet>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1,minimum-scale=1,maximum-scale=1.0,user-scalable=no,target-densitydpi=device-dpi"
    />
    <meta
      name="description"
      content="Islamic solutions is a easy solution provider to complex issues. inheritance calculator, mehram detector and other useful tools all in one."
    />
    <meta name="facebook-domain-verification" content="rxrvzwl9kma9zp62f0a3n0otxwc670" />
    <meta property="og:url" content="https://www.islamic-solutions.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Islamic Solutions" />
    <meta
      property="og:description"
      content="Islamic solutions is a easy solution provider to complex issues. inheritance calculator, mehram detector and other useful tools all in one. It also supports multiple languages."
    />
    <meta
      property="og:image"
      content="https://public-artifcats.s3.amazonaws.com/preview-link-banner.png"
    />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="Islamic Solutions" />
    <title>islamic solutions</title>
  </Helmet>
);
