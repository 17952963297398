import { useState, useCallback, useEffect } from 'react';

export const useDebounce = (value, delay = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState();

  const debounce = func => {
    let timer;
    return (...args) => {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, delay);
    };
  };

  const startTimer = useCallback(
    debounce(val => {
      setDebouncedValue(val);
    }),
    [],
  );

  useEffect(() => {
    startTimer(value);
  }, [value]);

  return debouncedValue;
};

export function useDebouncedEffect(callback, deps, delay) {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, deps);
}

export function useDebouncedMemo(factory, deps, delay, defaultValue = null) {
  const [debouncedValue, setDebouncedValue] = useState(defaultValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      const newValue = factory();
      setDebouncedValue(newValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, deps);

  return debouncedValue;
}
