export const Logo = ({ color = '#00FFC2' }) => (
  <svg
    width="289"
    height="294"
    viewBox="0 0 289 294"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.3142 140.5C-19.6858 193 67.3142 286.5 128.314 225.5"
      stroke={color}
      stroke-width="42"
    />
    <path d="M43 140L121 62" stroke={color} stroke-width="42" stroke-linecap="round" />
    <path
      d="M125.814 228L203.814 150"
      stroke={color}
      stroke-width="42"
      stroke-linecap="round"
    />
    <path
      d="M246.5 193.95C308.5 141.45 221.5 47.95 160.5 108.95"
      stroke={color}
      stroke-width="42"
    />
    <path
      d="M245.814 194.45L167.814 272.45"
      stroke={color}
      stroke-width="42"
      stroke-linecap="round"
    />
    <path
      d="M163 106.45L85 184.45"
      stroke={color}
      stroke-width="42"
      stroke-linecap="round"
    />
    <circle cx="165.5" cy="22.5" r="22.5" fill={color} />
  </svg>
);
