import { ArrowsInSimple, ArrowsOutSimple, CheckCircle } from '@phosphor-icons/react';
import { useGlobal } from 'context.js';
import { useState } from 'react';
import styled from 'styled-components';
import { genders, religions } from 'utility';

export const lineColors = {
  spouse: 'red',
  fatherSon: '#0033A0',
  fatherDaughter: '#004D00',
  motherSon: '#F57C00',
  motherDaughter: '#6A1B9A',
  grey: 'grey',
};

export const initialNodes = [
  {
    id: '1',
    type: 'person',
    data: {
      name: 'You',
      fixedY: 0,
      alive: true,
      religion: religions.muslim,
      murderer: false,
      illegitimate: false,
      gender: genders.male,
    },
    position: { x: 0, y: 0 },
  },
];

const MapContainer = styled.div`
  position: absolute;
  right: 7px;
  top: 7px;
  z-index: 1001;
  pointer-events: none;
  background: white;
  opacity: 0.7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;

  border-radius: 5px;

  span,
  b {
    font-size: 12px;
    display: block;
  }
`;

const Line = styled.div`
  width: 29px;
  height: 5px;
  background: ${({ bgColor }) => bgColor};
  margin-left: 7px;
  border-radius: 3px;
`;
const Dots = styled.div`
  width: 5px;
  height: 5px;
  background: ${({ bgColor }) => bgColor};
  margin-left: 3px;
  border-radius: 3px;
`;

export const MapGuide = () => {
  const { langAdapt } = useGlobal();

  return (
    <MapContainer>
      <b>{langAdapt('spouse')}</b>
      <div className="d-flex align-items-center justify-content-between">
        <span>{langAdapt('married')}</span>
        <Line bgColor={lineColors.spouse} />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span>{langAdapt('divorced')}</span>
        <div className="d-flex">
          <Dots bgColor={lineColors.spouse} />
          <Dots bgColor={lineColors.spouse} />
          <Dots bgColor={lineColors.spouse} />
          <Dots bgColor={lineColors.spouse} />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span>{langAdapt('nonAliveSpouse')}</span>
        <Line bgColor={lineColors.grey} />
      </div>
      <b className="pt-2">{langAdapt('father')}</b>
      <div className="d-flex align-items-center justify-content-between">
        <span>{langAdapt('fatherSon')}</span>
        <Line bgColor={lineColors.fatherSon} />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span>{langAdapt('fatherDaughter')}</span>
        <Line bgColor={lineColors.fatherDaughter} />
      </div>
      <b className="pt-2">{langAdapt('mother')}</b>
      <div className="d-flex align-items-center justify-content-between">
        <span>{langAdapt('motherSon')}</span>
        <Line bgColor={lineColors.motherSon} />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span>{langAdapt('motherDaughter')}</span>
        <Line bgColor={lineColors.motherDaughter} />
      </div>
    </MapContainer>
  );
};

const SaveContainer = styled.div`
  position: absolute;
  left: 7px;
  top: 7px;
  z-index: 1001;
  pointer-events: none;
  opacity: 0.5;
  padding: 10px;
  font-size: 14px;
  transition: all 0.3s;
  color: green;
  &.loading {
    color: black;
    opacity: 0.3;
  }
`;

export const SaveBar = ({ saving }) => {
  return (
    <SaveContainer className={`d-flex align-items-center ${saving ? 'loading' : ''}`}>
      <CheckCircle weight="fill" />
      <span>&nbsp;sync</span>
    </SaveContainer>
  );
};

const ExpandContainer = styled.div`
  right: 10px;
  bottom: 10px;
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.secondaryDark};
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  z-index: 1001;
`;

export const ExpandCollapse = ({
  setHeightReduce,
  defaultHeightReducer,
  scrollToTop,
}) => {
  const [expand, setExpand] = useState(false);

  const toggleExpand = () => {
    if (expand) {
      setHeightReduce(defaultHeightReducer);
    } else {
      setHeightReduce(80);
    }

    setExpand(!expand);

    setTimeout(() => {
      scrollToTop();
    }, [200]);
  };

  return (
    <ExpandContainer onClick={toggleExpand}>
      {expand ? <ArrowsInSimple size={22} /> : <ArrowsOutSimple size={22} />}
    </ExpandContainer>
  );
};
