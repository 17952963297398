import { adaptlang } from 'languages';
import React, { createContext, useContext, useState } from 'react';
import { languages } from 'utility';

const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const [language, setLanguage] = useState(languages.english);

  const langAdapt = key => adaptlang(key, language);

  return (
    <GlobalContext.Provider
      value={{
        language,
        setLanguage,
        langAdapt,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export const useGlobal = () => useContext(GlobalContext);
