import { FamilyTree } from 'page-components';
import styled from 'styled-components';
import { TreeContextProvider } from 'hooks/treeContext';
import { MuteLabel } from 'elements';
import { Tag } from 'antd';
import { InfoToolTip } from 'elements/Common';
import { Header } from 'layouts/components';
import { ResponsivePageConatiner } from 'layouts';
import { useGlobal } from 'context.js';
import { CustomMetaTags } from 'page-components/metadata';

const TagsContainer = styled.div`
  display: flex;
  column-gap: 30px;
  row-gap: 10px;
  flex-wrap: wrap;
`;

export function RelationsPage() {
  const { langAdapt } = useGlobal();
  return (
    <>
      <CustomMetaTags
        title="mehram detector"
        description="An islamic mehram detector based that can solve complex issues including maternal siblings, paternal siblings, divorced case, foster mother"
      />
      <ResponsivePageConatiner>
        <Header>
          <h3 className="mb-4 d-flex align-items-center">
            {langAdapt('mehramDetector')}
            <Tag className="ml-2" color="gold">
              {langAdapt('hanafi')}
            </Tag>
            <InfoToolTip className="ml-0" title={langAdapt('hanafiTooltip')} />
          </h3>
        </Header>
        <TagsContainer className="mb-2 d-flex">
          <div>
            <Tag bordered={false} color="purple">
              {langAdapt('mehram')}
            </Tag>
            <MuteLabel>{langAdapt('mehramDetails')}</MuteLabel>
            <InfoToolTip title={langAdapt('mehramTooltip')} className="ml-0" />
          </div>
          <div>
            <Tag bordered={false} color="red">
              {langAdapt('nonMehram')}
            </Tag>
            <MuteLabel>
              {langAdapt('nonMehramDetails')}
              <InfoToolTip title={langAdapt('nonMehramTooltip')} className="ml-0" />
            </MuteLabel>
          </div>
          <div>
            <Tag bordered={false} color="magenta">
              {langAdapt('nikkahable')}
            </Tag>
            <MuteLabel>{langAdapt('nikkahableDetails')}</MuteLabel>
          </div>
          <div>
            <Tag bordered={false} color="blue">
              {langAdapt('nonNikkahable')}
            </Tag>
            <MuteLabel>{langAdapt('nonNikkahableDetails')}</MuteLabel>
          </div>
        </TagsContainer>
        <TreeContextProvider>
          <FamilyTree reduceHeight={160} mehramDetector />
        </TreeContextProvider>
      </ResponsivePageConatiner>
    </>
  );
}
