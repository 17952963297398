import { Button } from 'antd';
import styled from 'styled-components';

export const BlackButton = styled(Button)`
  background-color: black;
  color: white;
  &:hover {
    color: white !important;
    background-color: rgb(60, 60, 60) !important;
  }
`;
