import { useState } from 'react';
import { CommonUtility, genders, SharesUtility } from '../utility';
import { useDebouncedEffect } from './debounce';
import { RelationUtility } from 'page-components/family-tree/PersonNode/RelationUtility/common';

export const useShares = (id, personList, relations, isInheritance) => {
  const [personShare, setPersonShare] = useState({});
  const [isSolutionValid, setIsSolutionValid] = useState(true);

  const fatherShare = (father, hasAnyAliveChildren) => {
    if (!father) {
      return {};
    }

    const hasAnySon = RelationUtility.hasAnySon(id, relations, personList);

    const share = {};
    // has father
    if (RelationUtility.shareEligible(father)) {
      share[father.id] = SharesUtility.FatherShare(hasAnySon);
      return share;
    }

    // has no father

    const anyFather = RelationUtility.getAnyFather(id, personList, relations);

    if (!!anyFather) {
      share[anyFather.id] = SharesUtility.FatherShare(hasAnySon);
      return share;
    }

    if (!!anyFather || hasAnySon) {
      return share;
    }

    // no grandFather or grandson

    // fixed share brothers and sisters

    const { PaternalSiblingsData, RealSiblingsData, MaternalSiblingsData } =
      RelationUtility.getSiblings(id, relations, personList);

    if (!!MaternalSiblingsData.length) {
      if (MaternalSiblingsData.length === 1) {
        share[MaternalSiblingsData[0].id] = SharesUtility.MaternalSibling(
          1,
          false,
          false,
        );
      } else {
        const collectiveShare = SharesUtility.MaternalSibling(2, false, false);
        MaternalSiblingsData.forEach(mSibling => {
          share[mSibling.id] = {
            share: collectiveShare.share / MaternalSiblingsData.length,
          };
        });
      }
    }

    // Real Siblings
    const SisterCollectiveShare = SharesUtility.SisterShare(
      !!RealSiblingsData.sisters.length,
      false,
      !!RealSiblingsData.brothers.length,
      false,
      hasAnyAliveChildren,
    );

    if (SisterCollectiveShare && !!RealSiblingsData.sisters.length) {
      if (SisterCollectiveShare.residuary) {
        RealSiblingsData.sisters.forEach(sister => {
          share[sister.id] = SisterCollectiveShare;
        });
      } else {
        RealSiblingsData.sisters.forEach(sister => {
          share[sister.id] = {
            share: SisterCollectiveShare.share / RealSiblingsData.sisters.length,
          };
        });
      }
    }

    const BrotherCollectiveShare = SharesUtility.BrotherShare(false, false);
    if (BrotherCollectiveShare && RealSiblingsData.brothers.length) {
      RealSiblingsData.brothers.forEach(brother => {
        share[brother.id] = BrotherCollectiveShare;
      });
    }

    const anyBrother =
      !!RealSiblingsData.brothers.length || !!PaternalSiblingsData.brothers.length;

    // Parental Siblings
    const PaternalSisterCollectiveShare = SharesUtility.PaternalSisterShare(
      PaternalSiblingsData.sisters.length,
      false,
      false,
      anyBrother,
      RealSiblingsData.sisters.length,
      RealSiblingsData.brothers.length,
      hasAnyAliveChildren,
    );

    if (PaternalSisterCollectiveShare && !!PaternalSiblingsData.sisters.length) {
      if (PaternalSisterCollectiveShare.residuary) {
        PaternalSiblingsData.sisters.forEach(sister => {
          share[sister.id] = PaternalSisterCollectiveShare;
        });
      } else {
        PaternalSiblingsData.sisters.forEach(sister => {
          share[sister.id] = {
            share:
              PaternalSisterCollectiveShare.share / PaternalSiblingsData.sisters.length,
          };
        });
      }
    }

    const PaternalBrotherShare = SharesUtility.PaternalBrotherShare(
      false,
      false,
      !!RealSiblingsData.brothers.length,
    );

    if (PaternalBrotherShare && PaternalSiblingsData.brothers.length) {
      PaternalSiblingsData.brothers.forEach(brother => {
        share[brother.id] = PaternalBrotherShare;
      });
    }

    // No Fixed Share Or Residuary

    const hasAnyResiduary = Object.values(share).some(share => !!share.residuary);
    if (hasAnyResiduary) {
      return share;
    }

    // find any son in upward lineage
    let sonFoundAtFatherLevel = -1;
    let sonFoundLevel = -1;

    // check sibling of each father
    function siblingsOf(personId) {
      const { PaternalSiblingsData, RealSiblingsData } = RelationUtility.getSiblings(
        personId,
        relations,
        personList,
        false,
      );

      function searchInSons(brotherId, level) {
        if (level > sonFoundLevel && sonFoundLevel !== -1) {
          return;
        }

        const sons = RelationUtility.getSons(brotherId, relations, personList);
        const aliveSons = sons.filter(x => RelationUtility.shareEligible(x));
        if (aliveSons.length) {
          aliveSons.forEach(aliveSon => {
            share[aliveSon.id] = SharesUtility.SonShare();
          });
          sonFoundLevel = level;
        } else {
          for (const son of sons) {
            searchInSons(son.id, level + 1);
          }
        }
      }

      if (PaternalSiblingsData.brothers.length || RealSiblingsData.brothers.length) {
        const aliveRealBrother = RealSiblingsData.brothers.filter(x =>
          RelationUtility.shareEligible(x),
        );
        const alivePaternalBrother = PaternalSiblingsData.brothers.filter(x =>
          RelationUtility.shareEligible(x),
        );

        // if father has any brother alive
        if (aliveRealBrother.length || alivePaternalBrother.length) {
          if (aliveRealBrother.length) {
            aliveRealBrother.forEach(brother => {
              share[brother.id] = SharesUtility.SonShare();
            });
          }

          if (!aliveRealBrother.length && alivePaternalBrother.length) {
            alivePaternalBrother.forEach(brother => {
              share[brother.id] = SharesUtility.SonShare();
            });
          }
          return;
        } else {
          // if son of any brother of father is alive
          for (const brother of RealSiblingsData.brothers) {
            searchInSons(brother.id, 1);
          }
          if (sonFoundLevel === -1) {
            for (const brother of PaternalSiblingsData.brothers) {
              searchInSons(brother.id);
            }
          }
        }
      }

      if (sonFoundAtFatherLevel === -1) {
        const parents = RelationUtility.getParentRelation(personId, relations);
        const father =
          parents[0]?.data.startLabel === 'Father'
            ? parents[0]?.source
            : parents[1]?.source;
        if (father) {
          // check siblings of Father's father
          siblingsOf(father);
        }
      }
    }

    // start from the person
    siblingsOf(id);
    return share;
  };

  const motherShare = (person, mother, aliveSpouses, father, hasAnyAliveChildren) => {
    const hasAnyFather = !!RelationUtility.getAnyFather(id, personList, relations);
    let hasMother = !!mother;

    const share = {};
    if (RelationUtility.shareEligible(mother)) {
      const { PaternalSiblingsData, RealSiblingsData, MaternalSiblingsData } =
        RelationUtility.getSiblings(id, relations, personList);

      const nOfSiblings =
        (PaternalSiblingsData?.brothers?.length || 0) +
        (PaternalSiblingsData?.sisters?.length || 0) +
        (RealSiblingsData?.brothers?.length || 0) +
        (RealSiblingsData?.sisters?.length || 0) +
        (MaternalSiblingsData?.length || 0);

      share[mother.id] = SharesUtility.MotherShare(
        hasAnyAliveChildren,
        nOfSiblings,
        person.data.gender,
        RelationUtility.shareEligible(father),
        !!aliveSpouses.length,
      );
      return share;
    }

    // no mother we can shift to grandmothers
    let motherMother = null;
    if (hasMother) {
      motherMother = RelationUtility.getMotherMother(mother?.id, personList, relations);
    }
    let fatherMother = null;

    const collectiveShare = {
      share: 4,
    };

    if (!hasAnyFather) {
      fatherMother = RelationUtility.getFatherMother(father?.id, personList, relations);
    }

    if (!!motherMother && !fatherMother) {
      // Only True GrandMother
      share[motherMother.id] = collectiveShare;
    } else if (!motherMother && !!fatherMother) {
      // Only Father's Mother
      share[fatherMother.id] = collectiveShare;
    } else if (!!motherMother && !!fatherMother) {
      // Both
      share[motherMother.id] = {
        share: 2,
      };
      share[fatherMother.id] = {
        share: 2,
      };
    }

    return share;
  };

  const childrenShare = () => {
    const share = {};
    let daughters = [];
    let sonFoundAtHeirarchy = -1;
    let highestHeirarchy = -1;

    const shareDownwardLineage = (id, heirarchy) => {
      if (sonFoundAtHeirarchy < heirarchy && sonFoundAtHeirarchy !== -1) {
        return;
      }

      const children = RelationUtility.getChildrens(id, relations);
      const childrenData = personList.filter(x => children.includes(x.id));

      const aliveChildren = childrenData.filter(x => RelationUtility.shareEligible(x));

      const aliveDaughters = aliveChildren.filter(x => x.data.gender === genders.female);
      const aliveSons = aliveChildren.filter(x => x.data.gender === genders.male);

      const sons = childrenData.filter(x => x.data.gender === genders.male);

      const nOfAliveSons = aliveSons.length || 0;

      if (highestHeirarchy < heirarchy) {
        highestHeirarchy = heirarchy;
      }
      daughters.push(...aliveDaughters.map(daughter => ({ ...daughter, heirarchy })));
      if (!sons.length) return;

      if (nOfAliveSons) {
        if (sonFoundAtHeirarchy < heirarchy) {
          sonFoundAtHeirarchy = heirarchy;
        }

        aliveSons.forEach(aliveSon => {
          share[aliveSon.id] = SharesUtility.SonShare();
          share[aliveSon.id].heirarchy = heirarchy;
        });
        return;
      }

      for (const son of sons) {
        shareDownwardLineage(son.id, heirarchy + 1);
      }
    };
    shareDownwardLineage(id, 1);

    // Aftermath

    const hasAnySon = sonFoundAtHeirarchy !== -1;
    // filter unneccassary shares
    Object.keys(share).forEach(sonId => {
      if (share.heirarchy > sonFoundAtHeirarchy) {
        delete share[sonId];
      }
    });

    // filter unneccassary daughters
    if (hasAnySon) {
      daughters = daughters.filter(x => x.heirarchy <= sonFoundAtHeirarchy);
    }

    if (!daughters.length) return share;

    let previousDaughters = 0;

    function daughterShare(heirarchy) {
      if (highestHeirarchy < heirarchy) {
        return;
      }

      const daughtersAtHeirarchy = daughters.filter(x => x.heirarchy === heirarchy);
      const nDaughtersAtHeirarchy = daughtersAtHeirarchy.length;

      if (nDaughtersAtHeirarchy) {
        const collectiveShare = SharesUtility.DaughterShare(
          nDaughtersAtHeirarchy + previousDaughters,
          sonFoundAtHeirarchy <= heirarchy && sonFoundAtHeirarchy !== -1,
          previousDaughters,
        );

        if (collectiveShare.share) {
          daughtersAtHeirarchy.forEach(daughter => {
            share[daughter.id] = {
              share: collectiveShare.share / nDaughtersAtHeirarchy,
            };
          });
        } else {
          daughtersAtHeirarchy.forEach(daughter => {
            share[daughter.id] = {
              ...collectiveShare,
              ratio: collectiveShare.ratio,
            };
          });
        }

        previousDaughters += daughtersAtHeirarchy.length;
      }

      if (previousDaughters >= 2) {
        return;
      }
      daughterShare(heirarchy + 1);
    }

    daughterShare(1);

    return share;
  };

  useDebouncedEffect(
    () => {
      if (!personList.length || !relations.length || !id || !isInheritance) return;
      let shares = {};
      const person = personList.find(x => x.id === id);
      // Get Spouses
      let aliveSpouses = [];
      if (person.data.gender === genders.male) {
        aliveSpouses = RelationUtility.getNonDivorcedWives(id, relations);
      } else {
        aliveSpouses = RelationUtility.getNonDivorcedHusband(id, relations);
      }
      const aliveSpousesData = personList.filter(x => aliveSpouses.includes(x.id));

      aliveSpouses = aliveSpousesData.filter(x => RelationUtility.shareEligible(x));

      // Get Parents
      const parents = RelationUtility.getParents(id, relations);
      const parentsData = personList.filter(x => parents.includes(x.id));

      const father = parentsData.find(x => x.data.gender === genders.male);
      const mother = parentsData.find(x => x.data.gender === genders.female);

      // Get Children
      const hasAnyAliveChildren = RelationUtility.hasAliveChildren(
        id,
        relations,
        personList,
      );

      // Children Logic
      const share = childrenShare();
      shares = {
        ...shares,
        ...share,
      };

      // Father Logic
      const shareOfFather = fatherShare(father, hasAnyAliveChildren);
      shares = {
        ...shares,
        ...shareOfFather,
      };

      // Mother Logic
      const shareOfMother = motherShare(
        person,
        mother,
        aliveSpouses,
        father,
        hasAnyAliveChildren,
      );
      shares = {
        ...shares,
        ...shareOfMother,
      };

      // Spouse Logic
      if (aliveSpouses.length) {
        let collectiveShare = {};

        if (person.data.gender === genders.male) {
          collectiveShare = SharesUtility.WifeShare(hasAnyAliveChildren);
        } else {
          collectiveShare = SharesUtility.HusbandShare(hasAnyAliveChildren);
        }

        aliveSpouses.forEach(spouse => {
          shares[spouse.id] = {
            share: collectiveShare.share / aliveSpouses.length,
            spouse: true,
          };
        });
      }

      setIsSolutionValid(CommonUtility.isValidObject(shares));
      setPersonShare(shares);
    },
    [id, personList, relations, isInheritance],
    500,
  );

  return { personShare, isSolutionValid };
};
