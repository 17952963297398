import {
  CreditCard,
  FacebookLogo,
  GlobeHemisphereWest,
  LinkedinLogo,
  Mailbox,
  Phone,
  PuzzlePiece,
  YoutubeLogo,
} from '@phosphor-icons/react';
import { Headphones } from '@phosphor-icons/react/dist/ssr';
import { Images } from 'images';
import { Logo } from 'images/logo';
import { DesktopMode, MobileMode } from 'layouts';
import { DefaultMetaTags } from 'page-components/metadata';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 10px;
  padding-bottom: 10px;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.grey};
    &:hover {
      color: black;
    }
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <div className="d-flex align-items-center">
        <Logo color="black" />
        <span className="ml-1">islamic solutions.</span>
      </div>
      <div>
        <Link to="/app">Solutions</Link>
      </div>
    </HeaderContainer>
  );
};

const PageContainer = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.background};
`;

const TopSection = styled.div`
  min-height: 80vh;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  color: white;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    box-shadow: inset 0 0 10px #03030385;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  svg {
    position: absolute;
    height: 50vh;
    width: 50vw;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.05;
    left: 10%;
    z-index: 0;
  }
`;

const ImageSection = styled.div`
  img {
    width: 33vw;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.7);
  }

  display: flex;
  flex-wrap: wrap;
  flex: 4;
  position: relative;
  height: inherit;
  max-width: 100%;
  overflow: hidden;
  padding-left: 30px;

  .image-1 {
    position: absolute;
    top: 20%;
  }
  .image-2 {
    position: absolute;
    top: 20%;
    left: calc(33vw + 40px);
  }

  .image-3 {
    top: calc(20% + 17vw + 30px);
    position: absolute;
    left: 90px;
  }
  .image-4 {
    top: calc(20% + 17vw + 30px);
    position: absolute;
    left: calc(33vw + 120px);
  }
`;

const TextSection = styled.div`
  height: inherit;
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px;
  position: relative;

  p {
    text-transform: uppercase;
    letter-spacing: 5px;
    opacity: 0.7;
  }

  h1 {
    font-size: 60px;
    white-space: nowrap;
    margin: 30px 0;
  }

  span {
    opacity: 0.5;
    line-height: 30px;
  }

  .white {
    opacity: 1;
  }

  @media only screen and (max-width: 601px) {
    padding: 20px;

    h1 {
      font-size: 40px;
      margin: 20px 0;
    }

    span {
      opacity: 0.5;
      line-height: 20px;
    }
  }

  @media only screen and (max-width: 461px) {
    padding: 20px;

    .tag-line {
      font-size: 12px;
      font-weight: 300;
      opacity: 0.8;
    }

    h1 {
      font-size: 35px;
    }

    span {
      opacity: 0.8;
      line-height: unset;
      font-weight: 300;
    }
  }

  @media only screen and (max-width: 401px) {
    h1 {
      font-size: 30px;
    }

    span {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 350px) {
    h1 {
      font-size: 25px;
    }

    span {
      font-size: 14px;
    }
  }
`;

const Button = styled(Link)`
  background: rgb(0, 115, 63);
  min-width: 100px;
  max-width: fit-content;
  padding: 0 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  white-space: nowrap;

  &.bordered {
    background: none;
    color: rgb(0, 115, 63);
    border: 1px solid rgb(0, 115, 63);
    &:hover {
      background: rgb(0, 115, 63);
      color: white;
    }
  }
`;

const MainSection = () => {
  return (
    <TopSection>
      <Logo color="white" className="icon" />
      <TextSection>
        <p className="tag-line">providing islamic solutions</p>
        <h1>
          Easy Islamic <br />
          Solutions for Complex <br /> Problems
        </h1>
        <span>
          We aim to provide solutions from islamic guidance to people. These can scale
          from self meditation to better governance. Be it financial or social, we got you
          covered.
        </span>
        <div className="d-flex align-items-center mt-4 pt-4">
          <span className="white">Explore our app now!</span>
          <Button to="/app" className="ml-2">
            App
          </Button>
        </div>
      </TextSection>
      <ImageSection>
        <img className="image image-1" src={Images.landing.screen1} alt="app-image1" />
        <img className="image image-2" src={Images.landing.screen1} alt="app-image2" />
        <img className="image image-3" src={Images.landing.screen2} alt="app-image3" />
        <img className="image image-4" src={Images.landing.screen2} alt="app-image4" />
      </ImageSection>
    </TopSection>
  );
};

const HighlightContainer = styled.div`
  margin-top: 10px;
  border-radius: 15px;
  width: 100%;
  background: rgba(184, 198, 190, 0.6);
  display: flex;
  justify-content: space-between;
  row-gap: 20px;
  column-gap: 20px;
  flex-wrap: wrap;
  padding: 25px 60px;
  span {
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
  }
  .highlight-item {
    flex: 1;
    min-width: 220px;
  }

  @media only screen and (max-width: 601px) {
    padding: 20px;
  }
`;

const IconContainer = styled.div`
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  color: white;
  background: ${({ theme }) => theme.darkGreen};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Highlight = () => {
  return (
    <HighlightContainer>
      <div className="d-flex align-items-center highlight-item">
        <IconContainer>
          <GlobeHemisphereWest size={30} weight="fill" />
        </IconContainer>
        <div className="d-flex flex-column ml-2">
          <b>3 Languages</b>
          <span className="mt-1">We support upto 3 lanuages</span>
        </div>
      </div>
      <div className="d-flex align-items-center highlight-item">
        <IconContainer>
          <PuzzlePiece size={30} weight="fill" />
        </IconContainer>
        <div className="d-flex flex-column ml-2">
          <b>2 Modules</b>
          <span className="mt-1">We have added 2 modules</span>
        </div>
      </div>
      <div className="d-flex align-items-center highlight-item">
        <IconContainer>
          <Headphones size={30} weight="fill" />
        </IconContainer>
        <div className="d-flex flex-column ml-2">
          <b>Support</b>
          <span className="mt-1">We have online support!</span>
        </div>
      </div>
      <div className="d-flex align-items-center highlight-item">
        <IconContainer>
          <CreditCard size={30} weight="fill" />
        </IconContainer>
        <div className="d-flex flex-column ml-2">
          <b>Free of cost</b>
          <span className="mt-1">We aim to provide solutions for free!</span>
        </div>
      </div>
    </HighlightContainer>
  );
};

const SolutionContainer = styled.div`
  padding: 80px;
  @media only screen and (max-width: 601px) {
    padding: 20px;
    margin: 40px 0;
  }
`;

const SolutionText = styled.div`
  span {
    text-transform: uppercase;
    letter-spacing: 5px;
    color: rgb(0, 115, 63);
  }

  h3 {
    font-size: 60px;
  }

  p {
    white-space: nowrap;
    opacity: 0.7;
  }

  @media only screen and (max-width: 950px) {
    .button-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }

    .button {
      margin-left: 0;
      margin-top: 20px;
    }

    .main-paragraph p {
      max-height: fit-content;
    }

    h3 {
      font-size: 50px;
    }
  }

  @media only screen and (max-width: 769px) {
    .solution-container {
      flex-direction: column;
    }
    .button-container {
      margin-left: 0;
      padding-left: 0;
    }
    .main-paragraph {
      white-space: break-spaces;
    }
  }

  @media only screen and (max-width: 601px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    h3 {
      font-size: 40px;
      text-align: center;
      padding: 0;
      margin: 0;
      margin-top: 10px;
    }

    .main-paragraph {
      text-align: center;
    }

    .button-container {
      align-items: center;
    }
  }

  @media only screen and (max-width: 461px) {
    h3 {
      font-size: 35px;
    }
  }

  @media only screen and (max-width: 401px) {
    h3 {
      font-size: 30px;
    }

    .main-paragraph {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 350px) {
    h3 {
      font-size: 25px;
    }
  }
`;

const SolutionCard = styled(Link)`
  text-decoration: none;
  color: black;
  img {
    width: 25vw;
    min-width: 250px;
    border-radius: 6px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25vw;
  min-width: 250px;

  p {
    padding: 0 20px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  span {
    padding: 0 20px;
    text-align: center;
    opacity: 0.7;
    font-size: 14px;
  }
`;

const SolutionCardContainer = styled.div`
  display: flex;
  column-gap: 40px;
  row-gap: 40px;
  flex-wrap: wrap;
  margin-top: 60px;
  @media only screen and (max-width: 789px) {
    justify-content: center;
  }
`;

const Solutions = () => {
  return (
    <SolutionContainer>
      <SolutionText>
        <span className="tag-line">our solutions</span>
        <div className="d-flex justify-content-between solution-container">
          <h3 className="mr-4 pr-4">
            Our Solutions Simplify
            <br /> your Problems
          </h3>
          <div className="d-flex ml-4 pl-4 mt-4 button-container">
            <p className="d-flex flex-column mr-4 pr-4 main-paragraph">
              <DesktopMode>
                <p>This platforms aim to take issues that often</p>
                <p>seems complex and solve them in intuitive</p>
                <p>user-friendly UIs from islamic guidance.</p>
              </DesktopMode>
              <MobileMode>
                This platforms aim to take issues that often seems complex and solve them
                in intuitive user-friendly UIs from islamic guidance.
              </MobileMode>
            </p>
            <Button to="/app" className="bordered ml-4 pl-4 button">
              Explore Now
            </Button>
          </div>
        </div>
      </SolutionText>
      <SolutionCardContainer>
        <SolutionCard to="/app/inheritance">
          <img src={Images.landing.screen1} alt="inheritance-calculator" />
          <p>Inheritance Calculator</p>
          <span>
            We have a tree based inheritance calculator, add as many family members as you
            want with limitless possibilities. Be it divorced case or polygamy, we got you
            covered!
          </span>
        </SolutionCard>
        <SolutionCard to="/app/relation">
          <img src={Images.landing.screen2} alt="inheritance-calculator" />
          <p>Mehram Detector</p>
          <span>
            We have a tree based mehram detector, add as many family members as you want
            with limitless relation possibilities. Be it foster mother or
            paternal/maternal siblings, we got you covered!
          </span>
        </SolutionCard>
      </SolutionCardContainer>
    </SolutionContainer>
  );
};

const FooterContainter = styled.div`
  min-height: 80px;
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  position: relative;
  overflow: hidden;
  padding: 80px;
  box-shadow: inset 0 0 10px #030303;

  .logo svg {
    position: absolute;
    height: 30vh;
    width: 30vw;
    top: -50%;
    opacity: 0.05;
    left: 5%;
    z-index: 0;
  }

  .inner-logo svg {
    width: 20px;
    height: 20px;
  }

  color: white;

  .main-container {
    row-gap: 20px;
    column-gap: 20px;
  }

  @media only screen and (max-width: 769px) {
    .main-container {
      flex-direction: column;
      align-items: start;
    }
  }

  @media only screen and (max-width: 601px) {
    padding: 20px;
  }
`;

const LeftSection = styled.div`
  flex: 2;
  p {
    font-weight: 300;
    max-width: 80%;
  }
  @media only screen and (max-width: 769px) {
    flex: 1;
  }
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
`;

const RightSection = styled.div`
  flex: 3;
  font-weight: 300;
  display: flex;
  justify-content: end;
  @media only screen and (max-width: 769px) {
    flex: 1;
  }
`;

const Footer = () => {
  return (
    <FooterContainter>
      <div className="logo">
        <Logo color="white" />
      </div>
      <div className="d-flex main-container">
        <LeftSection>
          <div className="d-flex inner-logo align-items-center mb-4 pb-2">
            <Logo color="white" />
            <span className="ml-1">islamic solutions.</span>
          </div>
          <p>
            We are always open to listening your requests and issues reported. We want to
            work on other features in future as well. let's work together to make this
            platform super-awesome
          </p>
          <div className="d-flex mt-4 pt-4">
            <SocialIcon>
              <YoutubeLogo weight="fill" />
            </SocialIcon>
            <SocialIcon className="mx-3">
              <FacebookLogo weight="fill" />
            </SocialIcon>
            <SocialIcon>
              <LinkedinLogo weight="fill" />
            </SocialIcon>
          </div>
        </LeftSection>
        <RightSection>
          <div>
            <p>Contact</p>
            <div className="d-flex align-items-center mt-4 pt-4">
              <Phone weight="fill" />
              <span className="ml-2 mb-1">+92 316 9666987</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <Mailbox weight="fill" />
              <span className="ml-2 mb-1">shahfahadiffi@gmail.com</span>
            </div>
          </div>
        </RightSection>
      </div>
    </FooterContainter>
  );
};

export const LandingPage = () => {
  return (
    <>
      <DefaultMetaTags />
      <PageContainer>
        <Header />
        <MainSection />
        <Highlight />
        <Solutions />
        <Footer />
      </PageContainer>
    </>
  );
};
