import { Binoculars } from '@phosphor-icons/react';
import { DefaultMetaTags } from 'page-components/metadata';
import styled from 'styled-components';

const PageContainer = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    font-weight: normal;
    font-size: 30px;
  }
  p {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export function NotFoundScreen() {
  return (
    <>
      <DefaultMetaTags />
      <PageContainer>
        <h3>Page Not Found...</h3>
        <p>Sorry we are unable to find that page</p>
        <Binoculars size={120} />
      </PageContainer>
    </>
  );
}
