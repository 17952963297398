import { GlobalProvider, SidebarProvider } from 'context.js';
import { SecureLayout } from 'layouts';
import { RelationsPage, TreePage } from 'pages/app';
import { LandingPage } from 'pages/Landing';
import { NotFoundScreen } from 'pages/NotFound';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom';

export default function Home() {
  return (
    <Router>
      <GlobalProvider>
        <SidebarProvider>
          <Routes>
            <Route
              path="app"
              element={
                <SecureLayout>
                  <Outlet />
                </SecureLayout>
              }
            >
              <Route path="inheritance" element={<TreePage />} />
              <Route path="relation" element={<RelationsPage />} />
              <Route index element={<Navigate to="/app/inheritance" replace />} />
              <Route path="*" element={<Navigate to="/app/inheritance" replace />} />
            </Route>
            <Route index element={<LandingPage />} />
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </SidebarProvider>
      </GlobalProvider>
    </Router>
  );
}
