import { genders } from 'utility';
import { RelationUtility } from './common';
import toast from 'react-hot-toast';
import { checkMehram } from './Mehram';

export const canBeParent = (
  childId,
  parentId,
  relationsList,
  personList,
  showMessage = false,
) => {
  const personTo = personList.find(x => x.id === parentId);
  const personToGender = personTo.data.gender;

  const personFrom = personList.find(x => x.id === childId);
  const personFromGender = personFrom.data.gender;

  const personFromParents = RelationUtility.getParents(childId, relationsList);

  if (personFromParents.length >= 2) {
    if (showMessage) {
      toast.error(`${personFrom?.data?.name} already has parents`);
    }
    return false;
  }

  if (personFromParents.length === 1) {
    const parent = personList.find(x => x.id === personFromParents[0]);

    const personFromHasFather = parent?.data.gender === genders.male;

    if (personFromHasFather && personToGender === genders.male) {
      if (showMessage) {
        toast.error(`${personFrom?.data?.name} already has a father`);
      }
      return false;
    } else if (!personFromHasFather && personToGender === genders.female) {
      if (showMessage) {
        toast.error(`${personFrom?.data?.name} already has a mother`);
      }

      return false;
    }
  }

  const mehram = checkMehram(childId, parentId, relationsList, personList, false);

  if (mehram.mehram) {
    if (showMessage) {
      toast.error(`${personFrom?.data?.name}'s mehram cannot be a parent`);
    }

    return false;
  }

  let spouses = [];
  if (personFromGender === genders.male) {
    spouses = RelationUtility.getWives(childId, relationsList);
  } else {
    spouses = RelationUtility.getHusband(childId, relationsList);
  }

  for (const spouse of spouses) {
    const spouseMehram = checkMehram(spouse, parentId, relationsList, personList, false);
    if (spouseMehram.mehram) {
      if (showMessage) {
        toast.error(`${personFrom?.data?.name}'s spouse's mehram cannot be a parent`);
      }

      return false;
    }
  }

  const parentMehram = checkMehram(
    personFromParents[0],
    parentId,
    relationsList,
    personList,
    false,
  );

  if (parentMehram.mehram) {
    if (showMessage) {
      toast.error(`${personFrom?.data?.name}'s parent's Mehram cannot be a parent`);
    }

    return false;
  }

  return true;
};
