import { CommonUtility, genders, primaryRelations } from 'utility';
import { RelationUtility } from './common';
import toast from 'react-hot-toast';

/*
  1. Father-children and their lineage
  2. Mother-children and their lineage
  3. Own Lineage
  4. Mother Sibling from GrandFather/GrandMother
  5. Father Sibling from GrandFather/GrandMother
  6. Is not their 1,2,3,4,5

  Not Spouse
  1. Father-children and their lineage
  2. Mother-children and their lineage
  3. Own Lineage
  4. Mother Sibling from GrandFather/GrandMother
  5. Father Sibling from GrandFather/GrandMother
  6. Is not their 1,2,3,4,5
*/

export const canBeSpouse = (
  personFromId,
  personToId,
  relationsList,
  personList,
  showErrors = false,
) => {
  const personFrom = personList.find(x => x.id === personFromId);
  const personTo = personList.find(x => x.id === personToId);

  if (personFrom.data.gender === personTo.data.gender) {
    if (showErrors) {
      toast.error('Cannot marry same gender');
    }
    return false;
  }

  if (showErrors) {
    // no need to check limit as I only want mehram and nikkah able booleans
    if (!canHaveSpouse(personFrom, personTo, relationsList, personList, showErrors)) {
      return false;
    }

    if (!canHaveSpouse(personTo, personFrom, relationsList, personList, showErrors)) {
      return false;
    }
  }

  // Person To is not in Person From Lineage
  if (
    checkPerson1InOtherRelations(
      personFromId,
      personToId,
      relationsList,
      showErrors,
      personFrom.data.name || 'source',
      personTo.data.name || 'target',
      personList,
    )
  ) {
    return false;
  }

  // Person From is not in Person To Lineage
  if (
    checkPerson1InOtherRelations(
      personToId,
      personFromId,
      relationsList,
      showErrors,
      personTo.data.name || 'target',
      personFrom.data.name || 'source',
      personList,
    )
  ) {
    return false;
  }

  // To-do living/divorced spouse
  // can marry spouse sibling and there lineage but not spouse's mother and spouse's lineage

  const personToSpouseRelative = checkPerson1SpouseInOtherRelations(
    personToId,
    personFromId,
    relationsList,
    personList,
    showErrors,
  );

  if (personToSpouseRelative) {
    return false;
  }

  const personFromSpouseRelative = checkPerson1SpouseInOtherRelations(
    personFromId,
    personToId,
    relationsList,
    personList,
    showErrors,
  );

  if (personFromSpouseRelative) {
    return false;
  }

  const canInterMarry = RelationUtility.religionsAcceptance(personFrom, personTo);

  if (!canInterMarry) {
    if (showErrors) {
      toast.error('Religions not in compliance.');
    }
    return false;
  }

  return true;
};

const checkPerson1SpouseInOtherRelations = (
  personFromId,
  personToId,
  relationsList,
  personList,
  showErrors,
) => {
  let spouses = [];
  const personFrom = personList.find(x => x.id === personFromId);
  const personTo = personList.find(x => x.id === personToId);

  if (personFrom.data.gender === genders.male) {
    spouses = RelationUtility.getWives(personFromId, relationsList);
  } else {
    spouses = RelationUtility.getHusband(personFromId, relationsList);
  }

  for (const spouse of spouses) {
    const relation = relationsList.find(
      x =>
        [x.target, x.source].includes(spouse) &&
        [x.target, x.source].includes(personFromId) &&
        x.data.relation === primaryRelations.spouse,
    );
    const spouseData = personList.find(x => x.id === spouse);
    if (!spouseData.data.alive || relation.data.divorced) {
      // only check lineage
      if (isPerson1inPerson2Lineage(spouse, personToId, relationsList, personList)) {
        return true;
      }
      if (isPerson1inPerson2Lineage(personToId, spouse, relationsList, personList)) {
        return true;
      }
    } else {
      // Person To is not in Spouse Lineage
      const spouseIsRelatedTo1 = checkPerson1InOtherRelations(
        personToId,
        spouse,
        relationsList,
        showErrors,
        personTo.data.name || 'target',
        `${personFrom.data.name || 'source'}'s spouse`,
        personList,
        true,
      );
      if (spouseIsRelatedTo1) {
        return spouseIsRelatedTo1;
      }

      // Spouse is not in person To Lineage
      const person1IsRelatedToS = checkPerson1InOtherRelations(
        spouse,
        personToId,
        relationsList,
        showErrors,
        `${personFrom.data.name || 'source'}'s spouse`,
        personTo.data.name || 'target',
        personList,
        true,
      );
      if (person1IsRelatedToS) {
        return person1IsRelatedToS;
      }
    }
  }
  return false;
};

const canHaveSpouse = (person, isMarryingTo, relationsList, personList, showErrors) => {
  if (person.data.gender === genders.female) {
    const nonDivorcee = RelationUtility.getNonDivorcedHusband(person.id, relationsList);

    const aliveHusband = personList.filter(
      x => nonDivorcee.includes(x.id) && x.data.alive,
    );

    if (CommonUtility.isValidArray(aliveHusband) && isMarryingTo.data.alive) {
      // Already has 1 husband
      if (showErrors) {
        toast.error(`${person.data.name} Already has a husband`);
      }
      return false;
    }
  } else {
    const nonDivorcee = RelationUtility.getNonDivorcedWives(person.id, relationsList);

    const aliveWives = personList.filter(x => nonDivorcee.includes(x.id) && x.data.alive);

    if (aliveWives?.length >= 4 && isMarryingTo.data.alive) {
      // Already has 4 wives
      if (showErrors) {
        toast.error(`${person.data.name} Already has 4 wives`);
      }
      return false;
    }
  }

  return true;
};

export const checkPerson1InOtherRelations = (
  person1,
  person2,
  relationList,
  showErrors = false,
  person1Name,
  personToName,
  personList,
) => {
  const parentsOfFrom = RelationUtility.getParents(person2, relationList);

  for (const parent of parentsOfFrom) {
    // case - 1 Paternal Siblings and their children
    // case - 2 Maternal Siblings and their children
    if (isPerson1inPerson2Lineage(person1, parent, relationList, personList)) {
      if (showErrors) {
        toast.error(`${person1Name} is ${personToName}'s Sibling or Sibling's child`);
      }
      return true;
    }
  }
  // case - 3 Own Children
  if (isPerson1inPerson2Lineage(person1, person2, relationList, personList, false)) {
    if (showErrors) {
      toast.error(`${person1Name} is ${personToName}'s child or grandchild`);
    }
    return true;
  }

  // case-4 Paternal uncles and aunties
  // case-5 Maternal uncles and aunties
  for (const parent of parentsOfFrom) {
    const parentsParent = RelationUtility.getParents(parent, relationList);
    for (const grandParent of parentsParent) {
      const unclesAunties = RelationUtility.getChildrens(grandParent, relationList);
      if (unclesAunties.includes(person1)) {
        if (showErrors) {
          toast.error(`${person1Name} is ${personToName}'s uncle or aunty`);
        }
        return true;
      }
    }
  }

  return false;
};

export const isPerson1inPerson2Lineage = (
  person1Id,
  person2Id,
  relationList,
  personList,
  canMarryUnMarriedSpouse = true,
) => {
  const children = RelationUtility.getChildrens(person2Id, relationList);

  if (!canMarryUnMarriedSpouse) {
    const person2 = personList.find(x => x.id === person2Id);
    if (person2) {
      let spouses = [];
      if (person2.data.gender === genders.male) {
        spouses = RelationUtility.getWives(person2Id, relationList);
      } else {
        spouses = RelationUtility.getHusband(person2Id, relationList);
      }
      if (spouses.includes(person1Id)) {
        return true;
      }
    }
  }

  if (!CommonUtility.isValidArray(children)) return false;

  for (const child of children) {
    if (child === person1Id) {
      return true;
    }
  }

  for (const child of children) {
    const inLineage = isPerson1inPerson2Lineage(
      person1Id,
      child,
      relationList,
      personList,
      canMarryUnMarriedSpouse,
    );
    if (inLineage) {
      return true;
    }
  }
};
