import { CommonUtility, genders, primaryRelations, religions } from 'utility';

export class RelationUtility {
  static getChildrens = (personId, relations) =>
    this.getChildrenRelation(personId, relations)
      ?.filter(x => !x.data.foster)
      ?.map(x => x.target);

  static getParents = (personId, relations) =>
    this.getParentRelation(personId, relations)
      ?.filter(x => !x.data.foster)
      ?.map(x => x.source);

  static getAllChildrens = (personId, relations) =>
    this.getChildrenRelation(personId, relations)?.map(x => x.target);

  static getAllParents = (personId, relations) =>
    this.getParentRelation(personId, relations)?.map(x => x.source);

  static getWives = (personId, relations) =>
    relations
      .filter(x => x.data.relation === primaryRelations.spouse && x.source === personId)
      .map(x => x.target);

  static getHusband = (personId, relations) =>
    relations
      .filter(x => x.data.relation === primaryRelations.spouse && x.target === personId)
      .map(x => x.source);

  static getWivesRelation = (personId, relations) =>
    relations.filter(
      x => x.data.relation === primaryRelations.spouse && x.source === personId,
    );

  static getHusbandRelation = (personId, relations) =>
    relations.filter(
      x => x.data.relation === primaryRelations.spouse && x.target === personId,
    );

  static getNonDivorcedHusband = (personId, relations) => {
    return this.getHusband(
      personId,
      relations.filter(x => !x.data.divorced),
    );
  };

  static getNonDivorcedWives = (personId, relations) => {
    return this.getWives(
      personId,
      relations.filter(x => !x.data.divorced),
    );
  };

  static getParentRelation = (personId, relations) =>
    relations.filter(
      x => x.data.relation === primaryRelations.parent && x.target === personId,
    );

  static getChildrenRelation = (personId, relations) =>
    relations.filter(
      x => x.data.relation === primaryRelations.parent && x.source === personId,
    );

  static hasSpouseLimit = (peronsId, personGender, personList, relationList) => {
    if (personGender === genders.male) {
      const wives = this.getNonDivorcedWives(peronsId, relationList);
      const wivesData = personList.filter(x => wives.includes(x.id));
      const aliveWives = wivesData.filter(x => !!x.data.alive);
      return aliveWives?.length < 4;
    } else {
      const husbands = this.getNonDivorcedHusband(peronsId, relationList);
      const husbandData = personList.filter(x => husbands.includes(x.id));
      const aliveHusband = husbandData.filter(x => !!x.data.alive);
      return aliveHusband?.length < 1;
    }
  };

  static getMother = (personId, personList, relationList) => {
    const parents = this.getParents(personId, relationList);
    const parentsData = personList.filter(x => parents.includes(x.id));

    const mother = parentsData.find(x => x.data.gender === genders.female);
    return mother;
  };

  static getMotherMother = (personId, personList, relationList) => {
    const parents = this.getParents(personId, relationList);

    const mother = personList.find(
      x => parents.includes(x.id) && x.data.gender === genders.female,
    );

    if (!mother) {
      return null;
    }

    if (this.shareEligible(mother)) {
      return mother;
    }

    return this.getMotherMother(mother.id, personList, relationList);
  };

  static getFatherMother = (personId, personList, relationList) => {
    const parents = this.getParents(personId, relationList);

    const mother = personList.find(
      x => parents.includes(x.id) && x.data.gender === genders.female,
    );

    const father = personList.find(
      x => parents.includes(x.id) && x.data.gender === genders.male,
    );

    if (!mother || !father) {
      return null;
    }

    if (this.shareEligible(mother)) {
      return mother;
    }

    return this.getMotherMother(father.id, personList, relationList);
  };

  static getAnyFather = (personId, personList, relationList) => {
    const parents = this.getParents(personId, relationList);

    const father = personList.find(
      x => parents.includes(x.id) && x.data.gender === genders.male,
    );

    if (!father) {
      return null;
    }

    if (this.shareEligible(father)) {
      return father;
    }

    return this.getAnyFather(father.id, personList, relationList);
  };

  static getSons = (personId, relationList, personList) => {
    const children = this.getChildrens(personId, relationList);
    const sons = personList.filter(
      x => children.includes(x.id) && x.data.gender === genders.male,
    );
    return sons;
  };

  static hasAnySon = (personId, relationList, personList) => {
    const sons = this.getSons(personId, relationList, personList);
    if (!sons.length) {
      return false;
    }

    const hasAnySonAlive = sons.some(x => this.shareEligible(x));
    if (hasAnySonAlive) {
      return true;
    } else {
      for (const son of sons) {
        if (this.hasAnySon(son.id, relationList, personList)) {
          return true;
        }
      }
    }

    return false;
  };

  static hasAliveChildren = (personId, relationList, personList) => {
    const children = this.getChildrens(personId, relationList);

    if (!CommonUtility.isValidArray(children)) {
      return false;
    }

    const childrenData = personList.filter(
      x => children.includes(x.id) && this.shareEligible(x),
    );

    if (childrenData.length > 0) {
      return true;
    }

    for (const child of children) {
      const hasAliveGrandChild = this.hasAliveChildren(child, relationList, personList);
      if (hasAliveGrandChild) {
        return true;
      }
    }

    return false;
  };

  static shareEligible = person => {
    if (CommonUtility.isValidObject(person?.data)) {
      return (
        person.data.alive &&
        person.data.religion === religions.muslim &&
        !person.data.murderer &&
        !person.data.illegitimate
      );
    }
    return false;
  };

  static getSiblings = (personId, relationList, personList, checkAlive = true) => {
    const parents = this.getParentRelation(personId, relationList);
    const _father =
      parents[0]?.data.startLabel === 'Father' ? parents[0]?.source : parents[1]?.source;
    const _mother =
      parents[0]?.data.startLabel === 'Father' ? parents[1]?.source : parents[0]?.source;

    let childrenOfFather = this.getChildrens(_father, relationList);
    let childrenOfMother = this.getChildrens(_mother, relationList);

    // Remove self
    childrenOfFather = childrenOfFather.filter(x => x !== personId);
    childrenOfMother = childrenOfMother.filter(x => x !== personId);

    let SiblingsData = personList;

    if (checkAlive) {
      SiblingsData = personList.filter(
        x =>
          (childrenOfFather.includes(x.id) || childrenOfMother.includes(x.id)) &&
          this.shareEligible(x),
      );
    }

    const RealSiblingsData = SiblingsData.filter(
      x => childrenOfFather.includes(x.id) && childrenOfMother.includes(x.id),
    );
    const PaternalSiblingsData = SiblingsData.filter(
      x => childrenOfFather.includes(x.id) && !RealSiblingsData.find(y => y.id === x.id),
    );
    const MaternalSiblingsData = SiblingsData.filter(
      x => childrenOfMother.includes(x.id) && !RealSiblingsData.find(y => y.id === x.id),
    );

    return {
      MaternalSiblingsData,
      RealSiblingsData: {
        brothers: RealSiblingsData.filter(x => x.data.gender === genders.male),
        sisters: RealSiblingsData.filter(x => x.data.gender === genders.female),
      },
      PaternalSiblingsData: {
        brothers: PaternalSiblingsData.filter(x => x.data.gender === genders.male),
        sisters: PaternalSiblingsData.filter(x => x.data.gender === genders.female),
      },
    };
  };

  static religionsAcceptance = (personA, personB) => {
    const { gender: genderA, religion: religionA } = personA?.data || {};
    const { gender: genderB, religion: religionB } = personB?.data || {};

    if (religionA !== religions.muslim && religionB !== religions.muslim) {
      return true;
    }

    if (religionA === religions.muslim && religionB === religions.muslim) {
      return true;
    }

    const acceptedReligions = [religions.christian, religions.jew];

    if (genderA === genders.male && religionA === religions.muslim) {
      return acceptedReligions.includes(religionB);
    } else if (genderB === genders.male && religionB === religions.muslim) {
      return acceptedReligions.includes(religionB);
    } else {
      return false;
    }
  };
}
