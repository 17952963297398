import { useSidebar } from 'context.js';
import { useDesktopMediaQuery } from 'layouts/ResponsiveMedia';
import styled from 'styled-components';

const MobileHeader = styled.div`
  padding-left: 50px;
  padding-top: 10px;
  transition: all 0.2s;
  &.open {
    padding-left: 60px;
  }
`;

export const Header = ({ children }) => {
  const isDesktop = useDesktopMediaQuery();
  const { isOpen } = useSidebar();

  if (isDesktop) {
    return <div>{children}</div>;
  }

  return <MobileHeader className={isOpen ? 'open' : 'close'}>{children}</MobileHeader>;
};
