import { useMediaQuery } from 'react-responsive';

export const useDesktopMediaQuery = () => useMediaQuery({ query: '(min-width: 769px)' });

export const useMobileAndBelowMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 768px)' });

export function DesktopMode({ children }) {
  const isDesktop = useDesktopMediaQuery();

  return isDesktop ? children : null;
}

export function MobileMode({ children }) {
  const isTabletAndBelow = useMobileAndBelowMediaQuery();

  return isTabletAndBelow ? children : null;
}
