import toast from 'react-hot-toast';
import { genders } from 'utility';
import { isPerson1inPerson2Lineage } from './Spouse';
import { RelationUtility } from './common';
import { checkMehram } from './Mehram';

export const canBeFoster = (parentId, childId, relations, persons) => {
  const parent = persons.find(x => x.id === parentId);
  const child = persons.find(x => x.id === childId);
  const childGender = child.data.gender;

  if (parent.data.gender === genders.male) {
    toast.error('Can only add foster mother not father');
    return false;
  }

  const alreadyRelated = relations.some(
    x =>
      [x.target, x.source].includes(parentId) && [x.target, x.source].includes(childId),
  );
  if (alreadyRelated) {
    toast.error('They are already connected');
    return false;
  }

  // obvious youngs
  const isParentInChildLineage = isPerson1inPerson2Lineage(
    parentId,
    childId,
    relations,
    persons,
  );
  if (isParentInChildLineage) {
    toast.error('Foster mother cannot be from lineage');
    return false;
  }

  let spouses = [];
  if (childGender === genders.male) {
    spouses = RelationUtility.getWives(childId, relations);
  } else {
    spouses = RelationUtility.getHusband(childId, relations);
  }

  for (const spouse of spouses) {
    const spouseMehram = checkMehram(spouse, parentId, relations, persons, false);
    if (spouseMehram.mehram) {
      toast.error("Foster mother cannot be spouse's mehram");

      return false;
    }
  }

  return true;
};
