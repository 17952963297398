import styled from 'styled-components';
import { Sidebar } from './sidebar';
import { DesktopMode, MobileMode, useDesktopMediaQuery } from './ResponsiveMedia';
import { useSidebar } from 'context.js';

const PageContainer = styled.div`
  margin-left: 50px;
  background: ${({ theme }) => theme.background};
  border-radius: 10px;
  overflow: hidden;
  max-height: calc(100vh - 20px);
  min-height: calc(100vh - 20px);
  transition: all 0.2s;
`;

const MobilePageContainer = styled.div`
  margin-left: 50px;
  &.open {
    margin-left: 50px;
  }

  &.close {
    margin-left: 0px;
  }

  background: ${({ theme }) => theme.background};
  min-height: 100vh;
  transition: all 0.2s;
`;

const BoxContainer = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.primary};
`;

export const SecureLayout = ({ children }) => {
  const { isOpen } = useSidebar();

  return (
    <>
      <DesktopMode>
        <BoxContainer>
          <PageContainer>
            <Sidebar />
            {children}
          </PageContainer>
        </BoxContainer>
      </DesktopMode>
      <MobileMode>
        <MobilePageContainer className={isOpen ? 'open' : 'close'}>
          <Sidebar />
          {children}
        </MobilePageContainer>
      </MobileMode>
    </>
  );
};

const DesktopContainer = styled.div`
  padding: 20px;
  overflow: auto;
  max-height: calc(100vh - 20px);
`;

const MobileContainer = styled.div`
  padding: 10px;
  &.open {
    padding-left: 20px;
  }
`;

export const ResponsivePageConatiner = ({ children }) => {
  const { isOpen } = useSidebar();

  const isDesktop = useDesktopMediaQuery();
  if (isDesktop) {
    return <DesktopContainer>{children}</DesktopContainer>;
  }

  return (
    <MobileContainer className={isOpen ? 'open' : 'close'}>{children}</MobileContainer>
  );
};
