const toolTips = {
  totalAmountTooltip:
    'Include the value of any non-monetary items here. Calculate their worth and enter the total amount',
  totalLoansTooltip: 'Loans that were taken out by the deceased',
  testationMoneyTooltip:
    'Testamentary gifts cannot exceed one-third of the total estate value.',
  betaTooltip: 'This feature is under testing, results are unreliable',
  hanafiTooltip: 'This feature is based on Hanafi school of thought. More coming soon!',
  mehramTooltip:
    'This relations cannot be married (spouse is already in marriage). Also in this relation no veil or third person is required',
  nonMehramTooltip:
    'This relations requires that these two person must have a veil and a mehram when conversing',
  consultMufti:
    'Situation drawn has many conflicts and is nearly impossible. Hence the app is unable to provide solution. kindly, consult a mufti.',
};

const profileEditSidebar = {
  editPerson: 'Edit Person',
  name: 'Name',
  gender: 'Gender',
  genderTooltip: 'Changing gender will disturb relations',
  male: 'Male',
  female: 'Female',
  religion: 'Religion',
  religionTooltip: 'Cannot change religion when person is selected',
  muslim: 'Muslim',
  christian: 'Christian',
  jew: 'Jew',
  other: 'Other',
  alive: 'Alive',
  aliveTooltip: 'Check wether the person is alive',
  murderer: 'Murderer',
  murdererTooltip: 'Check wether the person is murderer of seleted person',
  illegitimate: 'Illegitimate',
  illegitimateTooltip: 'Check wether the person is Illegitimate',
  status: 'Status',
};

const graph = {
  spouse: 'Spouse',
  married: 'Married',
  divorced: 'Divorced',
  nonAliveSpouse: 'Non Alive Spouse',
  father: 'Father',
  fatherSon: 'Father-Son',
  fatherDaughter: 'Father-Daughter',
  mother: 'Mother',
  motherSon: 'Mother-Son',
  motherDaughter: 'Mother-Daughter',
};

const edgeLabels = {
  Mother: 'Mother',
  Father: 'Father',
  Son: 'Son',
  Daughter: 'Daughter',
  Parent: 'Parent',
  Husband: 'Husband',
  'Foster Mother': 'Foster Mother',
  Wife: 'Wife',
  Child: 'Child',
  markMarried: 'Mark Married',
  markDivorced: 'Mark Divorced',
  markReal: 'Mark Real',
  markFoster: 'Mark Foster',
};

const profileDetails = {
  noParents: 'No Parents',
  noFather: 'No Father',
  noMother: 'No Mother',
  only1Father: 'Can only have 1 Father',
  only1Mother: 'Can only have 1 Mother',
  amount: 'amount',
  share: 'share',
};

export const EnglishLanguage = {
  inhertiance: 'Inherit-ance',
  relation: 'Relation',
  inhertinaceTitle: 'Inheritance Distributer',
  estateValue: 'Estate Value',
  estateDetails: 'Estate Details',
  totalAmount: 'Total Amount',
  totalLoans: 'Total Loans',
  testationMoney: 'Worth money of Testation',
  remaining: 'Remaining',
  confirm: 'Confirm',
  cancel: 'Cancel',
  remove: 'Remove',
  edit: 'Edit',
  mehramDetector: 'Mehram Detector',
  hanafi: 'Hanafi',
  mehram: 'mehram',
  nonMehram: 'non-mehram',
  nikkahable: 'nikkah-able',
  nonNikkahable: 'not nikkah-able',
  mehramDetails: 'No parda or marriage.',
  nonMehramDetails: 'Requires parda.',
  nikkahableDetails: 'Can be married.',
  nonNikkahableDetails: `Can't be married.`,
  ...toolTips,
  ...profileEditSidebar,
  ...graph,
  ...edgeLabels,
  ...profileDetails,
};
