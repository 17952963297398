import { FamilyTree } from 'page-components';
import { TreeContextProvider, useTreeContext } from 'hooks/treeContext';
import { BlackButton, MaskedNumberFormField, MuteLabel } from 'elements';
import { Alert, Button, Form, Modal, Tag } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from 'react';
import { PencilSimple } from '@phosphor-icons/react';
import { InfoToolTip } from 'elements/Common';
import { CommonUtility } from 'utility';
import { Header } from 'layouts/components';
import { ResponsivePageConatiner } from 'layouts';
import { useGlobal } from 'context.js';
import { CustomMetaTags } from 'page-components/metadata';

const scheme = yup.object().shape({
  total_amount: yup
    .number()
    .required('Total Amount is required')
    .typeError('Must be a number'),
  loan: yup
    .number()
    .typeError('Must be a number')
    .nullable()
    .transform((_, val) => (Number(val) ? Number(val) : null))
    .min(0)
    .when('total_amount', (total_amount, scheme) =>
      scheme.test('loan', 'Should be less than total amount', loan => {
        if (!isNaN(total_amount) && !isNaN(loan)) {
          return loan <= total_amount;
        }
        return true;
      }),
    ),
  testation: yup
    .number()
    .typeError('Must be a number')
    .nullable()
    .transform((_, val) => (Number(val) ? Number(val) : null))
    .min(0)
    .when(['total_amount', 'loan'], (fields, schema) =>
      schema.test(
        'testation',
        'Cannot be more than 1/3 of remaining amount',
        testation => {
          const [total_amount, loan] = fields;
          if (!isNaN(total_amount) && !isNaN(loan) && !isNaN(testation)) {
            let remaining = Number(total_amount || 0) - Number(loan || 0);
            remaining = remaining / 3;
            return testation <= remaining;
          }
          return true;
        },
      ),
    ),
});

const InputsContainer = ({ isSolutionValid }) => {
  const { langAdapt } = useGlobal();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(scheme),
  });

  const [total_amount, loan] = watch(['total_amount', 'loan']);

  const [estateOpen, setEstateOpen] = useState(false);

  const { setShareData, shareData } = useTreeContext();

  const onSubmit = formValues => {
    const { loan, testation, total_amount } = formValues;
    const remainingAmount =
      Number(total_amount || 0) - Number(testation || 0) - Number(loan || 0);
    setShareData(d => ({
      ...d,
      amount: remainingAmount,
    }));
    onClose();
  };

  const onClose = () => {
    setEstateOpen(false);
  };

  const openPopup = () => {
    setEstateOpen(true);
  };

  return (
    <>
      <div className="d-flex mb-2 align-items-center">
        <MuteLabel>{langAdapt('estateValue')}</MuteLabel>
        <b className="ml-2">{CommonUtility.currencyFormat(shareData.amount)}</b>
        <BlackButton onClick={openPopup} className="ml-2">
          <PencilSimple weight="fill" />
          {langAdapt('edit')}
        </BlackButton>
      </div>
      {!isSolutionValid && (
        <Alert className="mb-2" type="error" message={langAdapt('consultMufti')} />
      )}
      <Modal
        open={estateOpen}
        onCancel={onClose}
        centered
        title={langAdapt('estateDetails')}
        footer={null}
      >
        <Form layout="vertical" className="my-4" onFinish={e => e.preventDefault()}>
          <MaskedNumberFormField
            control={control}
            vertical
            name="total_amount"
            errors={errors.total_amount}
            hint={errors.total_amount?.message}
            label={
              <MuteLabel className="d-flex align-items-center">
                {langAdapt('totalAmount')}
                <InfoToolTip title={langAdapt('totalAmountTooltip')} />
              </MuteLabel>
            }
            placeholder="Total amount"
          />
          <MaskedNumberFormField
            control={control}
            vertical
            name="loan"
            errors={errors.loan}
            hint={errors.loan?.message}
            label={
              <MuteLabel className="d-flex align-items-center">
                {langAdapt('totalLoans')}
                <InfoToolTip title={langAdapt('totalLoansTooltip')} />
              </MuteLabel>
            }
            placeholder="Enter any loan"
          />
          {total_amount && loan && (
            <div>
              <MuteLabel>
                {langAdapt('remaining')}:{' '}
                {CommonUtility.currencyFormat(total_amount - loan)}
              </MuteLabel>
            </div>
          )}
          <MaskedNumberFormField
            control={control}
            vertical
            name="testation"
            errors={errors.testation}
            hint={errors.testation?.message}
            label={
              <MuteLabel className="d-flex align-items-center">
                {langAdapt('testationMoney')}
                <InfoToolTip title={langAdapt('testationMoneyTooltip')} />
              </MuteLabel>
            }
            placeholder="Enter any testation in amount"
          />
        </Form>
        <div className="d-flex justify-content-end">
          <Button onClick={onClose}>{langAdapt('cancel')}</Button>
          <BlackButton onClick={handleSubmit(onSubmit)} className="ml-2">
            {langAdapt('confirm')}
          </BlackButton>
        </div>
      </Modal>
    </>
  );
};

export function TreePage() {
  const [isSolutionValid, setIsSolutionValid] = useState(true);
  const { langAdapt } = useGlobal();

  return (
    <>
      <CustomMetaTags
        title="inheritance calculator"
        description="An islamic inheritance calculator based that can solve complex issues including maternal siblings, paternal siblings, divorced case, foster mother"
      />
      <ResponsivePageConatiner>
        <Header>
          <h3 className="mb-4 d-flex align-items-center">
            {langAdapt('inhertinaceTitle')}
            <Tag className="ml-2" color="gold">
              Beta
            </Tag>
            <InfoToolTip className="ml-0" title={langAdapt('betaTooltip')} />
          </h3>
        </Header>
        <TreeContextProvider>
          <InputsContainer isSolutionValid={isSolutionValid} />
          <FamilyTree
            isInheritance
            setIsSolutionValid={setIsSolutionValid}
            reduceHeight={190}
          />
        </TreeContextProvider>
      </ResponsivePageConatiner>
    </>
  );
}
