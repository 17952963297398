import { useEdges, useNodes } from '@xyflow/react';
import { Button, Drawer, Form } from 'antd';
import { useTreeContext } from 'hooks/treeContext';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CommonUtility, genders, religions } from 'utility';
import {
  BlackButton,
  FormRadioField,
  FormSwitchFormField,
  FormTextFormField,
  MuteLabel,
} from 'elements';
import toast from 'react-hot-toast';
import { RelationUtility } from './PersonNode/RelationUtility/common';
import { InfoToolTip } from 'elements/Common';
import { useGlobal } from 'context.js';

const EditDetailSection = styled.div`
  .text {
    color: black;
  }
`;

const scheme = yup.object().shape({
  name: yup.string().required(),
  religion: yup.string().required(),
  gender: yup.string().required(),
  alive: yup.boolean().required(),
  murderer: yup.boolean().required(),
  illegitimate: yup.boolean().required(),
});

export const EditSidebar = ({ onNodeChange, removeNode, isInheritance }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(scheme),
  });

  const { langAdapt } = useGlobal();

  const { activeNode, setActiveNode, selectedNode } = useTreeContext();
  const nodes = useNodes();
  const edges = useEdges();

  const data = useMemo(() => nodes.find(x => x.id === activeNode)?.data, [activeNode]);

  const onlyHasParents = useMemo(() => {
    const relatedEdges = edges.filter(x => [x.source, x.target].includes(activeNode));
    const allParents = RelationUtility.getAllParents(activeNode, edges);
    return (relatedEdges?.length || 0) === (allParents?.length || 0);
  }, [edges, activeNode]);

  useEffect(() => {
    if (CommonUtility.isValidObject(data)) {
      reset(data);
    }
  }, [data]);

  const onClose = () => {
    setActiveNode(null);
  };

  const deleteNode = () => {
    if (nodes.length > 1) {
      removeNode(activeNode);
      setActiveNode(null);
    } else {
      toast.error('There must be atleast 1 person');
    }
  };

  const saveChanges = formValue => {
    onNodeChange(activeNode, formValue);
    setActiveNode(null);
  };

  return (
    <Drawer
      onClose={onClose}
      open={CommonUtility.isValidObject(data) && activeNode}
      title={langAdapt('editPerson')}
    >
      {CommonUtility.isValidObject(data) && (
        <EditDetailSection>
          <Form layout="vertical" onFinish={e => e.preventDefault()}>
            <FormTextFormField
              control={control}
              vertical
              name="name"
              errors={errors.name}
              hint={errors.name?.message}
              label={<MuteLabel>{langAdapt('name')}</MuteLabel>}
              placeholder={langAdapt('name')}
            />
            <FormRadioField
              name="gender"
              vertical
              errors={errors.gender}
              hint={errors.gender?.message}
              control={control}
              buttonStyle="solid"
              buttonLayout
              label={
                <MuteLabel className="d-flex align-items-center">
                  {langAdapt('gender')}
                  {!onlyHasParents && <InfoToolTip title={langAdapt('genderTooltip')} />}
                </MuteLabel>
              }
              disabled={!onlyHasParents}
              options={[
                { label: langAdapt('male'), value: genders.male },
                { label: langAdapt('female'), value: genders.female },
              ]}
            />
            <FormRadioField
              name="religion"
              errors={errors.religion}
              hint={errors.religion?.message}
              vertical
              control={control}
              disabled={isInheritance && activeNode === selectedNode}
              containerClass="my-4"
              label={
                <MuteLabel>
                  {langAdapt('religion')}
                  {isInheritance && activeNode === selectedNode && (
                    <InfoToolTip title={langAdapt('religionTooltip')} />
                  )}
                </MuteLabel>
              }
              options={[
                { label: langAdapt('muslim'), value: religions.muslim },
                { label: langAdapt('christian'), value: religions.christian },
                { label: langAdapt('jew'), value: religions.jew },
                { label: langAdapt('other'), value: religions.other },
              ]}
            />
            <MuteLabel>{langAdapt('status')}</MuteLabel>
            <div className="pt-2">
              <FormSwitchFormField
                label={
                  <MuteLabel className="d-flex align-items-center">
                    {langAdapt('alive')}
                    <InfoToolTip title={langAdapt('aliveTooltip')} />
                  </MuteLabel>
                }
                control={control}
                errors={errors.alive}
                hint={errors.alive?.message}
                name="alive"
                className="mr-2"
              />
              {isInheritance && (
                <>
                  <FormSwitchFormField
                    label={
                      <MuteLabel className="d-flex align-items-center">
                        {langAdapt('murderer')}
                        <InfoToolTip title={langAdapt('murdererTooltip')} />
                      </MuteLabel>
                    }
                    control={control}
                    errors={errors.murderer}
                    hint={errors.murderer?.message}
                    name="murderer"
                    className="mr-2"
                    containerClass="my-2"
                  />
                  <FormSwitchFormField
                    label={
                      <MuteLabel className="d-flex align-items-center">
                        {langAdapt('illegitimate')}
                        <InfoToolTip title={langAdapt('illegitimateTooltip')} />
                      </MuteLabel>
                    }
                    control={control}
                    errors={errors.illegitimate}
                    hint={errors.illegitimate?.message}
                    name="illegitimate"
                    className="mr-2"
                  />
                </>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <Button
                disabled={selectedNode === activeNode}
                onClick={deleteNode}
                className="mt-2"
                type="primary"
                danger
              >
                {langAdapt('remove')}
              </Button>
              <BlackButton
                onClick={handleSubmit(saveChanges)}
                className="mt-2  ml-2"
                type="primary"
              >
                {langAdapt('confirm')}
              </BlackButton>
            </div>
          </Form>
        </EditDetailSection>
      )}
    </Drawer>
  );
};
