const toolTips = {
  totalAmountTooltip:
    'قم بإدخال قيمة أي أشياء غير مالية هنا. احسب قيمتها وأدخل المبلغ الإجمالي',
  totalLoansTooltip: 'القروض التي أخذها المتوفى',
  testationMoneyTooltip: 'لا يمكن أن تتجاوز الوصية ثلث القيمة الإجمالية للميراث',
  betaTooltip: 'هذا القسم قيد الاختبار، والنتائج غير موثوقة',
  hanafiTooltip: 'تستند هذه الميزة إلى المذهب الحنفي. سيتم توفير المزيد قريبًا',
  mehramTooltip:
    'هذه العلاقة لا يمكن أن تكون زوجية (ما عدا الزوجين المتزوجين سابقًا). كما أنه لا يلزم وجود حجاب أو طرف ثالث في هذا السياق',
  nonMehramTooltip:
    'تتطلب هذه العلاقة وجود حجاب بين الطرفين عند التحدث، كما يجب أن يكونوا محارم',
  consultMufti:
    'الوضع المرسوم فيه صراعات كثيرة ويكاد يكون مستحيلاً. ومن ثم فإن التطبيق غير قادر على تقديم الحل. يرجى استشارة المفتي.',
};

const profileEditSidebar = {
  editPerson: 'تعديل تفاصيل الشخص.',
  name: 'الاسم',
  gender: 'الجنس',
  genderTooltip: 'تغيير الجنس سيؤدي إلى اختلال العلاقات في الموقع',
  male: 'ذكر',
  female: 'أنثى',
  religion: 'الدين',
  religionTooltip: 'لا يمكن تغيير الدين بعد تحديد الشخص',
  muslim: 'مسلم',
  christian: 'مسيحي',
  jew: 'يهودي',
  other: 'من أهل الكتاب بخلاف ذلك',
  alive: 'على قيد الحياة',
  aliveTooltip: 'تحقق مما إذا كان الشخص على قيد الحياة',
  murderer: 'قاتل',
  murdererTooltip: 'تحقق مما إذا كان الشخص هو قاتل الشخص المحدد',
  illegitimate: 'غير شرعي',
  illegitimateTooltip: 'تحقق مما إذا كان الشخص هو طفل غير شرعي',
  status: 'التفاصيل',
};

const graph = {
  spouse: 'الزوج',
  married: 'متزوج',
  divorced: 'مطلق',
  nonAliveSpouse: 'الزوج المتوفى',
  father: 'الأب',
  fatherSon: 'أب - ابن',
  fatherDaughter: 'أب - ابنة',
  mother: 'الأم',
  motherSon: 'أم - ابن',
  motherDaughter: 'أم - ابنة',
};

const edgeLabels = {
  Mother: 'أم',
  Father: 'أب',
  Son: 'ابن',
  Daughter: 'ابنة',
  Husband: 'زوج',
  Wife: 'زوجة',
  Child: 'طفل',
  markMarried: 'متزوج',
  Parent: 'الوالدين',
  markDivorced: 'مطلق',
  'Foster Mother': 'أم رضاعية',
  markReal: 'أم حقيقية',
  markFoster: 'أم رضاعية',
};

const profileDetails = {
  noParents: 'لا يوجد والدين',
  noFather: 'لا يوجد أب',
  noMother: 'لا توجد أم',
  only1Father: 'يمكن أن يكون له أب واحد فقط',
  only1Mother: 'يمكن أن يكون لها أم واحدة فقط',
  amount: 'كمية',
  share: 'حصہ',
};

export const ArabicLanguage = {
  inhertiance: 'الميراث',
  relation: 'محرمات',
  inhertinaceTitle: 'توزيع الميراث',
  estateValue: 'التركة',
  estateDetails: 'تفاصيل التركة',
  totalAmount: 'قيمة التركة',
  totalLoans: 'القروض',
  testationMoney: 'الوصية',
  remaining: 'الباقي',
  confirm: 'البيانات صحيحة',
  cancel: 'إلغاء',
  remove: 'إزالة',
  edit: 'تعديل التفاصيل',
  mehramDetector: 'محرمات',
  hanafi: 'حنفي',
  mehram: 'محرم',
  nonMehram: 'غير محرم',
  nikkahable: 'قابل للزواج',
  nonNikkahable: 'غير قابل للزواج',
  mehramDetails: 'غير قابل للزواج ولا حاجة للحجاب',
  nonMehramDetails: 'الحجاب ضروري',
  nikkahableDetails: 'قابل للزواج',
  nonNikkahableDetails: 'غير قابل للزواج',
  ...toolTips,
  ...profileEditSidebar,
  ...graph,
  ...edgeLabels,
  ...profileDetails,
};
