export class CommonUtility {
  static isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  static isString(x) {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  static isNotEmpty = item =>
    item !== undefined && item !== null && item !== '' && item.length !== 0;

  static isNotNull = item => item !== undefined && item !== null;

  static truncateString = (text, ellipsisString) =>
    (text || '').length > ellipsisString
      ? `${text.substring(0, ellipsisString)}...`
      : text;

  static numberWithCommas = x => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  static getAbbreviateNumber = (x, decimal = 1) => {
    if (Number.isNaN(x)) {
      return '-';
    }
    if (decimal) {
      const decimalValue =
        Math.round((x + Number.EPSILON) * 10 ** decimal) / 10 ** decimal;
      const formattedNumber = new Intl.NumberFormat('en-US', {
        notation: 'compact',
        minimumFractionDigits: 0,
        maximumFractionDigits: decimal,
      });
      return formattedNumber.format(Number(decimalValue));
    }
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  static objectToParams = obj => {
    let str = '';
    for (const key in obj) {
      if (obj[key] !== undefined && obj[key] !== null) {
        if (str !== '') {
          str += '&';
        }
        str += `${key}=${encodeURIComponent(obj[key])}`;
      }
    }
    return str;
  };

  static base64ToBlob = base64 => {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  };

  static timeoutPromise = ms => new Promise(resolve => setTimeout(resolve, ms));

  static roundNumber = (num, decimal = 2) =>
    !isNaN(num)
      ? Math.round((parseFloat(num) + Number.EPSILON) * 10 ** decimal) / 10 ** decimal
      : 0;

  static roundNumberWithComma = (num, decimal = 2) =>
    !isNaN(num)
      ? CommonUtility.numberWithCommas(
          Math.round((parseFloat(num) + Number.EPSILON) * 10 ** decimal) / 10 ** decimal,
        )
      : 0;

  static downloadFile = (url, fileName) => {
    // for non-IE
    const req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.responseType = 'blob';
    req.onload = () => {
      // Convert the Byte Data to BLOB object.
      const blob = new Blob([req.response], { type: 'octet/stream' });

      // Check the Browser type and download the File.
      const isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL || window.webkitURL;
        const link = url.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = fileName;
        a.href = link;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    };
    req.send();
  };

  static downloadCSV = (data, fileName) => {
    // Convert the Byte Data to BLOB object.
    const blob = new Blob([data], { type: 'text/csv' });

    // Check the Browser type and download the File.
    const isIE = false || !!document.documentMode;
    if (isIE) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = fileName;
      a.href = link;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  static getFixNumber = (value, digit = 2) => {
    if (!Number.isNaN(value)) {
      const rule = 10 ** digit;
      return Math.round(value * rule) / rule;
    }
    return null;
  };

  static copyToClipboard = toSave => {
    const textArea = document.createElement('textarea');
    textArea.value = toSave;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  };

  static isValidArray = data => data && Array.isArray(data) && data.length > 0;

  static isValidObject = data =>
    typeof data === 'object' && data ? Object.keys(data).length > 0 : false;

  static isValidNumber = data => !!data && !isNaN(data || 'a');

  static tryParseJSONObject(jsonString) {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
      return false;
    } catch {
      return false;
    }
  }

  static removeUnderscoreAndCapitalize(str) {
    if (this.isString(str) === false) return '';

    let words = str.split('_');

    let capitalizedWords = words.map(word => {
      let firstLetter = word.charAt(0).toUpperCase();
      let restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });

    let capitalizedStr = capitalizedWords.join(' ');

    return capitalizedStr;
  }

  static sortArrayObjectsByKey = (data, key) => {
    if (!this.isValidArray(data)) return [];
    return [...data].sort((a, b) => `${a[key]}`.localeCompare(`${b[key]}`));
  };

  static generateRandomId() {
    return Math.random().toString(36).slice(2, 11);
  }

  static getRandomNumber(numDigits = 2) {
    if (numDigits <= 0) {
      throw new Error('Number of digits must be a positive integer.');
    }

    // Calculate the minimum and maximum values for the given number of digits
    const min = Math.pow(10, numDigits - 1);
    const max = Math.pow(10, numDigits) - 1;

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static getCurrency = () => {
    return 'USD';
  };

  static currencyFormat = (value, currency, options = {}, decimalPoints = 2) => {
    const tempCurrency = currency || this.getCurrency();
    return isNaN(value || 0)
      ? value
      : new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: tempCurrency,
          maximumFractionDigits: decimalPoints,
          ...(options || {}),
        }).format(value || 0);
  };
}
