import { genders } from 'utility';
import { Plus } from '@phosphor-icons/react';
import { NodeToolbar, Position, useEdges, useNodes } from '@xyflow/react';
import { BlackButton } from 'elements';
import { useState } from 'react';
import { Tooltip } from 'antd';
import {
  generateParentEdge,
  generateParentNode,
  generateSpouseEdge,
  generateSpouseNode,
} from './EdgeUtility';
import { RelationUtility } from './RelationUtility/common';
import { useGlobal } from 'context.js';

const { Top, Right, Left } = Position;

const AddParentButton = ({ addParent, canHaveFather, canHaveMother }) => {
  const [addClicked, setAddClicked] = useState(
    canHaveFather && canHaveMother ? false : true,
  );

  const { langAdapt } = useGlobal();

  if (addClicked) {
    return (
      <>
        <Tooltip title={canHaveFather ? '' : langAdapt('only1Father')}>
          <BlackButton
            onClick={() => addParent(genders.male)}
            type="primary"
            disabled={!canHaveFather}
          >
            <Plus weight="bold" />
            {langAdapt('Father')}
          </BlackButton>
        </Tooltip>
        <Tooltip title={canHaveMother ? '' : langAdapt('only1Mother')}>
          <BlackButton
            onClick={() => addParent(genders.female)}
            className="ml-2 mb-1"
            type="primary"
            disabled={!canHaveMother}
          >
            <Plus weight="bold" />
            {langAdapt('Mother')}
          </BlackButton>
        </Tooltip>
      </>
    );
  }

  return (
    <BlackButton onClick={() => setAddClicked(true)}>
      <Plus weight="bold" />
      {langAdapt('Parent')}
    </BlackButton>
  );
};

export const Toolbars = ({
  gender,
  data,
  id,
  canHaveFather,
  canHaveMother,
  canHaveSpouse,
}) => {
  const edges = useEdges();
  const nodes = useNodes();
  const { langAdapt } = useGlobal();

  const Spouse = gender === genders.male ? langAdapt('Wife') : langAdapt('Husband');
  const addSpouse = pos => {
    const spouse = generateSpouseNode(gender);
    const edge = generateSpouseEdge(id, spouse.id, gender, pos);

    data.addNode(id, spouse, edge, pos);
  };

  const addParent = parentGender => {
    const parent = generateParentNode(parentGender);
    const edge = generateParentEdge(parent.id, id, gender, parentGender);

    data.addNode(id, parent, edge);

    const previousParent = RelationUtility.getParents(id, edges);

    if (!previousParent) {
      return;
    }

    const previousParentGender =
      parentGender === genders.male ? genders.female : genders.male;

    if (
      RelationUtility.hasSpouseLimit(
        previousParent[0],
        previousParentGender,
        nodes,
        edges,
      )
    ) {
      const wife = parentGender === genders.male ? previousParent[0] : parent.id;
      const husband = parentGender === genders.female ? previousParent[0] : parent.id;

      const edge = generateSpouseEdge(husband, wife, gender, 'left');
      data.addNewEdge(edge);
    }
  };

  const noSpouseMessage =
    gender === genders.male
      ? 'Can only have 4 alive wives'
      : 'Can only have 1 alive husband';

  return (
    <>
      <NodeToolbar position={Top}>
        <AddParentButton
          canHaveFather={canHaveFather}
          canHaveMother={canHaveMother}
          addParent={addParent}
        />
      </NodeToolbar>

      <NodeToolbar position={Right}>
        <Tooltip title={canHaveSpouse ? '' : noSpouseMessage}>
          <BlackButton disabled={!canHaveSpouse} onClick={addSpouse}>
            <Plus weight="bold" />
            {Spouse}
          </BlackButton>
        </Tooltip>
      </NodeToolbar>
      <NodeToolbar position={Left}>
        <Tooltip title={canHaveSpouse ? '' : noSpouseMessage}>
          <BlackButton disabled={!canHaveSpouse} onClick={() => addSpouse('left')}>
            <Plus weight="bold" />
            {Spouse}
          </BlackButton>
        </Tooltip>
      </NodeToolbar>
    </>
  );
};
