import { genders } from './strings';

export class SharesUtility {
  // shares are out of 24
  // shares should be given first
  // residuaries will be givern share based on ratio
  // get All remaining shares cannot be Two

  // if totalShares are less or more than 24 and no residury is there the denominator will be equal to Numerator
  // if totalShare are equal to or greater than 24 residury get no shares

  static HusbandShare(hasChildren) {
    if (!!hasChildren) {
      return {
        share: 6,
        spouse: true,
      };
    }
    return {
      share: 12,
      spouse: true,
    };
  }

  static WifeShare(hasChildren) {
    if (!!hasChildren) {
      return {
        share: 3,
        spouse: true,
      };
    }
    return {
      share: 6,
      spouse: true,
    };
  }

  static DaughterShare(nOfDaughters, hasSon, daughtersAtCloserHierarchy) {
    if (hasSon) {
      return {
        residuary: true,
        ratio: 1,
      };
    }

    if (daughtersAtCloserHierarchy) {
      return {
        share: 4,
      };
    }

    if (nOfDaughters === 1) {
      return {
        share: 12, // 1/2
      };
    }
    return {
      share: 16, // 2/3
    };
  }

  static SonShare() {
    return {
      residuary: true,
      ratio: 2,
    };
  }

  static SonDaughterShare(hasSons, hasOneDaughter, hasGrandSons, nOfGrandDaughters) {
    if (hasSons) {
      return 0;
    }

    if (hasGrandSons) {
      return {
        residuary: true,
        ratio: 1,
      };
    }

    if (hasOneDaughter) {
      return {
        share: 4,
      };
    } else if (nOfGrandDaughters === 1) {
      return {
        share: 12,
      };
    } else if (nOfGrandDaughters > 1) {
      return {
        share: 16,
      };
    }
  }

  static FatherShare(hasAnySon) {
    if (hasAnySon) {
      return {
        share: 4,
      };
    }

    return {
      residuary: true,
      getRemaining: true,
      share: 4,
    };
  }

  static MotherShare(hasChildren, nOfSibling, Gender, hasFather, hasSpouse) {
    if (hasChildren || nOfSibling > 1) {
      return {
        share: 4,
      };
    }

    if (hasFather && hasSpouse) {
      if (Gender === genders.male) {
        return {
          share: 6,
        };
      } else {
        return {
          share: 4,
        };
      }
    }

    return {
      share: 8,
    };
  }

  static FatherFatherShare(hasAnySon, hasFather) {
    if (hasFather) {
      return 0;
    }

    if (hasAnySon) {
      return {
        share: 4,
      };
    }

    return {
      residuary: true,
      getRemaining: true,
      share: 4,
    };
  }

  static FatherMotherShare(hasMother, hasFather, hasMotherMother) {
    if (hasMother || hasFather) {
      return 0;
    }

    if (hasMotherMother) {
      return {
        share: 2,
      };
    }

    return {
      share: 4,
    };
  }

  static MotherMotherShare(hasMother, hasFatherMother) {
    if (hasMother) {
      return 0;
    }

    if (hasFatherMother) {
      return {
        share: 2,
      };
    }

    return {
      share: 4,
    };
  }

  static SisterShare(nOfSister, hasAnyFather, hasRealBrother, hasAnySon, hasAnyDaughter) {
    if (hasAnySon || hasAnyFather) {
      return null;
    }

    if (hasRealBrother || hasAnyDaughter) {
      return {
        residuary: true,
        ratio: 1,
      };
    }

    if (nOfSister === 1) {
      return {
        share: 12,
      };
    }

    return {
      share: 16,
    };
  }

  static BrotherShare(hasAnyFather, hasAnySon) {
    if (hasAnySon || hasAnyFather) {
      return null;
    }

    return {
      residuary: true,
      ratio: 2,
    };
  }

  static PaternalBrotherShare(hasAnyFather, hasAnySon, hasRealBrother) {
    if (hasAnyFather || hasRealBrother || hasAnySon) {
      return null;
    }

    return {
      residuary: true,
      ratio: 2,
    };
  }

  static PaternalSisterShare(
    nOfSister,
    hasAnySon,
    hasAnyFather,
    hasAnyBrother,
    nOfRealSister,
    hasRealBrother,
    hasAnyDaughter,
  ) {
    if (hasAnySon || hasAnyFather || nOfRealSister > 1 || hasRealBrother) {
      return null;
    }

    if (hasAnyBrother || hasAnyDaughter) {
      return {
        residuary: true,
        ratio: 1,
      };
    }

    if (nOfRealSister === 1) {
      return {
        share: 4,
      };
    }

    if (nOfSister === 1) {
      return {
        share: 12,
      };
    }

    return {
      share: 16,
    };
  }

  static MaternalSibling(nOfMaternalSibling, hasAnySon, hasAnyFather) {
    if (hasAnySon || hasAnyFather) {
      return null;
    }

    if (nOfMaternalSibling === 1) {
      return {
        share: 4,
      };
    }

    return {
      share: 8,
    };
  }
}
