import { Handle, Position } from '@xyflow/react';
import styled from 'styled-components';
import { handleIds } from 'utility';

const FosterHandle = styled(Handle)`
  width: 10px;
  height: 9px;
  margin-top: 5px;
  background: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 5px;
    height: 8px;
    border-radius: 50px 50px 0 0;
    background: rgb(100, 100, 100);
  }

  &::before {
    left: 5px;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
  }

  &::after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
  }
`;

export const AllHandles = ({ canHaveSpouse, canHaveParents }) => {
  const hidden = {
    pointerEvents: 'none',
    opacity: '0',
  };

  const validateConnection = connection => {
    return connection.source !== connection.target;
  };

  return (
    <>
      {/* Parents */}
      <Handle
        id={handleIds.top}
        style={canHaveParents ? {} : hidden}
        position={Position.Top}
        type="target"
        isValidConnection={validateConnection}
      />
      <Handle
        type="source"
        position={Position.Top}
        style={canHaveParents ? {} : hidden}
        id={handleIds.top}
        isValidConnection={validateConnection}
      />

      <FosterHandle
        type="target"
        position={Position.Top}
        style={canHaveParents ? hidden : {}}
        id={handleIds.topFoster}
        isValidConnection={validateConnection}
      />
      <FosterHandle
        type="source"
        position={Position.Top}
        style={canHaveParents ? hidden : {}}
        id={handleIds.topFoster}
        isValidConnection={validateConnection}
      />

      {/* Children */}
      <Handle
        type="source"
        position={Position.Bottom}
        id={handleIds.bottom}
        isValidConnection={validateConnection}
      />
      <Handle
        id={handleIds.bottom}
        position={Position.Bottom}
        type="target"
        isValidConnection={validateConnection}
      />

      {/* Spouse */}

      <Handle
        id={handleIds.left}
        style={canHaveSpouse ? {} : hidden}
        position={Position.Left}
        type="target"
        isValidConnection={validateConnection}
      />
      <Handle
        id={handleIds.right}
        position={Position.Right}
        type="target"
        style={canHaveSpouse ? {} : hidden}
        isValidConnection={validateConnection}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={handleIds.right}
        style={canHaveSpouse ? {} : hidden}
        isValidConnection={validateConnection}
      />
      <Handle
        type="source"
        position={Position.Left}
        id={handleIds.left}
        style={canHaveSpouse ? {} : hidden}
        isValidConnection={validateConnection}
      />
    </>
  );
};
