const toolTips = {
  totalAmountTooltip:
    'کسی بھی غیر مالیاتی اشیاء کی قیمت یہاں شامل کریں۔ ان کی مالیت کا حساب لگائیں اور کل رقم درج کریں۔',
  totalLoansTooltip: 'وہ قرضے جو مرحوم نے لیے تھے۔',
  testationMoneyTooltip: 'وصیت کل جائیداد کی قیمت کے ایک تہائی سے زیادہ نہیں ہو سکتے۔',
  betaTooltip: 'یہ سیکشن جانچ کے تحت ہے، نتائج ناقابل اعتبار ہیں۔',
  hanafiTooltip: 'یہ خصوصیت حنفی مکتبہ فکر پر مبنی ہے۔ مزید جلد آرہا ہے',
  mehramTooltip:
    'یہ رشتہ شادی شدہ نہیں ہو سکتا (سوائے میاں بیوی کے پہلے سے شادی شدہ ہے). نیز اس سلسلے میں کسی پردے یا تیسرے شخص کی ضرورت نہیں۔',
  nonMehramTooltip:
    'اس تعلق کا تقاضا ہے کہ گفتگو کرتے وقت ان دونوں کا پردہ اور محرم ہونا ضروری ہے۔',
  consultMufti:
    'تیار کی گئی صورتحال میں بہت سے تنازعات ہیں اور یہ تقریباً ناممکن ہے۔ لہذا ایپ حل فراہم کرنے سے قاصر ہے۔ برائے مہربانی مفتی سے مشورہ کریں۔',
};

const profileEditSidebar = {
  editPerson: 'شخص کی تفصیلات میں ترمیم کریں۔',
  name: 'نام',
  gender: 'جنس',
  genderTooltip: 'جنس تبدیل کرنے سے ویب سائٹ میں تعلقات خراب ہوں گے۔',
  male: 'مرد',
  female: 'عورت',
  religion: 'دین',
  religionTooltip: 'جب شخص منتخب ہو جائے تو دین نہیں بدل سکتا',
  muslim: 'مسلمان',
  christian: 'عیسائی',
  jew: 'یہودی',
  other: 'اہل کتاب کے علاوہ',
  alive: 'زندہ',
  aliveTooltip: 'چیک کریں کہ آیا یہ شخص زندہ ہے۔',
  murderer: 'قاتل',
  murdererTooltip: 'چیک کریں کہ یہ شخص سلیکٹڈ شخص کا قاتل ہے۔',
  illegitimate: 'ناجائز',
  illegitimateTooltip: 'چیک کریں کہ آیا یہ شخص ناجائز بچہ ہے۔',
  status: 'تفصیلات',
};

const graph = {
  spouse: 'شریک حیات',
  married: 'شادی شدہ',
  divorced: 'طلاق یافتہ',
  nonAliveSpouse: 'فوت شدہ شریک حیات',
  father: 'باپ',
  fatherSon: 'باپ - بیٹا',
  fatherDaughter: 'باپ - بیٹی',
  mother: 'ماں',
  motherSon: 'ماں - بیٹا',
  motherDaughter: 'ماں - بیٹی',
};

const edgeLabels = {
  Mother: 'ماں',
  Father: 'باپ',
  Son: 'بیٹا',
  Daughter: 'بیٹی',
  Husband: 'شوہر',
  Wife: 'بیوی',
  Child: 'بچہ',
  Parent: 'والدین',
  markMarried: 'شادی شدہ',
  markDivorced: 'طلاق ہو گئی',
  'Foster Mother': 'رضاعی ماں',
  markReal: 'حقیقی ماں',
  markFoster: 'رضاعی ماں',
};

const profileDetails = {
  noParents: 'والدین نہیں ہیں',
  noFather: 'باپ نہیں ہے',
  noMother: 'ماں نہیں ہے',
  only1Father: 'صرف ایک باپ ہو سکتا ہے',
  only1Mother: 'صرف ایک ماں ہو سکتی ہے',
  amount: 'رقم',
  share: 'حصہ',
};

export const UrduLanguage = {
  inhertiance: 'وراثت',
  relation: 'محرمات',
  inhertinaceTitle: 'وراثت کی تقسیم',
  estateValue: 'ترکہ',
  estateDetails: 'ترکہ کی تفصیل',
  totalAmount: 'ترکہ کی قیمیت',
  totalLoans: 'قرض',
  testationMoney: 'وصیت',
  remaining: 'بقایا',
  confirm: 'تفصیلات درست ہیں',
  cancel: 'بند کریں',
  remove: 'ہٹا دیں',
  edit: 'تفصیلات درج کریں',
  mehramDetector: 'محرمات',
  hanafi: 'حنفی',
  mehram: 'محرم',
  nonMehram: 'نا محرم',
  nikkahable: 'قابل شادی',
  nonNikkahable: 'ناقابل شادی',
  mehramDetails: 'ناقابل شادی اور پردے کی ضرورت نہیں',
  nonMehramDetails: 'پردہ ضروری ہے',
  nikkahableDetails: 'قابل شادی',
  nonNikkahableDetails: 'ناقابل شادی',
  ...toolTips,
  ...profileEditSidebar,
  ...graph,
  ...edgeLabels,
  ...profileDetails,
};
