import { CommonUtility } from 'utility';
import { useDebouncedEffect } from './debounce';
import { useState } from 'react';
import { checkMehram } from 'page-components/family-tree/PersonNode/RelationUtility';

export const useMehramDetector = (
  personId,
  relations,
  personList,
  showMehramDetector = false,
) => {
  const [islamicRelation, setIslamicRelation] = useState({});

  useDebouncedEffect(
    () => {
      if (
        !showMehramDetector ||
        !personId ||
        !CommonUtility.isValidArray(relations) ||
        !CommonUtility.isValidArray(personList)
      )
        return;

      const relation = {};
      personList?.forEach(person => {
        if (person.id !== personId) {
          const relationObj = checkMehram(personId, person.id, relations, personList);
          relation[person.id] = relationObj;
        }
      });
      setIslamicRelation(relation);
      //
    },
    [showMehramDetector, personId, relations, personList],
    500,
  );

  return { islamicRelation };
};
