import styled from 'styled-components';
import { cloneElement, useState, useMemo, isValidElement } from 'react';
import { useGlobal, useSidebar } from 'context.js';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from 'images/logo';
import { languages, themeColors } from 'utility';
import { DesktopMode, MobileMode } from './ResponsiveMedia';
import { GlobeSimple, List } from '@phosphor-icons/react';
import { Dropdown } from 'antd';

const DesktopSidebar = styled.div`
  position: fixed;
  display: flex;
  z-index: 999;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  transition: all 0.2s;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  flex: 1;
  :not(.selectable-all) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  span,
  a {
    text-align: center;
  }
`;

const MobileSidebar = styled.div`
  position: fixed;
  display: flex;
  z-index: 999;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  top: 0;
  bottom: 0;
  &.close {
    left: -60px;
  }
  &.open {
    left: 0px;
  }
  width: 60px;
  transition: all 0.2s;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  flex: 1;
  padding-bottom: 10px;
  :not(.selectable-all) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  span,
  a {
    text-align: center;
  }
`;

const Hamburger = styled.div`
  top: 10px;
  position: fixed;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.secondaryDark};
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  transition: all 0.2s;
  &.close {
    left: 10px;
  }
  &.open {
    left: 70px;
  }
`;

const MenuIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primaryLight};
  cursor: pointer;
  color: ${({ theme }) => theme.grey};
  border-radius: 5px;
  margin-bottom: 5px;
  &.active {
    background: ${({ theme }) => theme.secondaryDark};
    color: ${({ theme }) => theme.white};
  }
  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

const HeaderBar = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  width: 100%;
`;

const LogoHeaderBar = styled(HeaderBar)`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  a {
    user-select: none;
  }
  max-height: 55px;
  min-height: 55px;
  svg {
    width: 26px;
    height: 26px;
  }
`;

const MenuLink = styled(Link)`
  font-size: 10px;
  color: ${({ theme }) => theme.grey};
  padding: 7px 0;
  position: relative;
  max-width: 40px;
  text-decoration: none;
  &.low-opacity {
    opacity: 0.6;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 12px;

  .sidebar-icon-main {
    height: 24px;
    width: 24px;
    object-fit: contain;
  }
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.background};
    .sidebar-icon-main {
      color: ${({ theme }) => theme.white} !important;
    }
  }

  &.active {
    color: ${({ theme }) => theme.white} !important;
    .sidebar-icon-main {
      color: ${({ theme }) => theme.white} !important;
    }
  }
`;

const MenusContainer = styled.div`
  max-height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const preventDrag = e => {
  e.preventDefault();
};

function MenuHeaderBar({ isCollapsed }) {
  return (
    <Link href="/app" passHref>
      <LogoHeaderBar isCollapsed={isCollapsed}>
        <Logo color={themeColors.white} />
      </LogoHeaderBar>
    </Link>
  );
}

const CountBadge = styled.div`
  background: ${({ theme }) => theme.danger};
  position: absolute;
  top: 10px;
  right: 2px;
  border-radius: 3px;
  font-size: 10px;
  color: ${({ theme }) => theme.white};

  height: 15px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Menu({ item, activateMenu, isMenuActive, currentPath }) {
  const active = currentPath?.includes(item.parentLink);

  const count = useMemo(() => {
    if (!item.count) return null;
    if (item.count > 98) {
      return 99;
    }
    return item.count;
  }, [item]);

  return (
    <MenuLink
      to={item.path}
      className={`${active || isMenuActive ? 'active' : ''} ${
        item.lowerOpacity ? 'low-opacity' : ''
      }`}
      onDragStart={preventDrag}
    >
      <MenuIconContainer className={`${active || isMenuActive ? 'active' : ''}`}>
        {isValidElement(item.icon) && cloneElement(item.icon)}
      </MenuIconContainer>
      <span>{item.text}</span>

      {!!count && <CountBadge>{count}</CountBadge>}
    </MenuLink>
  );
}

const FooterContainer = styled.div`
  .icon-container {
    &:hover {
      background: ${({ theme }) => theme.secondaryDark};
    }
  }
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 10px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.grey};
  }
`;

const LanguageOption = styled.div`
  color: grey;
  &.active {
    font-weight: bold;
    color: black;
  }
`;

const Footer = () => {
  const { language, setLanguage } = useGlobal();

  const items = [
    {
      key: languages.arabic,
      label: (
        <LanguageOption className={language === languages.arabic ? 'active' : ''}>
          عربی
        </LanguageOption>
      ),
    },
    {
      key: languages.english,
      label: (
        <LanguageOption className={language === languages.english ? 'active' : ''}>
          English
        </LanguageOption>
      ),
    },
    {
      key: languages.urdu,
      label: (
        <LanguageOption className={language === languages.urdu ? 'active' : ''}>
          اردو
        </LanguageOption>
      ),
    },
  ];

  const onClick = ({ key }) => {
    setLanguage(key);
  };

  return (
    <FooterContainer>
      <Dropdown menu={{ items, onClick }}>
        <MenuIconContainer className="icon-container">
          <GlobeSimple size={24} />
        </MenuIconContainer>
      </Dropdown>
      <span>{language}</span>
    </FooterContainer>
  );
};

export function Sidebar() {
  const [activeMenu, setActiveMenu] = useState(-1);
  const location = useLocation();

  const { isCollapsed, setIsCollapsed, menus, isOpen, setIsOpen } = useSidebar();

  const toggleOpenSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <DesktopMode>
        <DesktopSidebar>
          <MenusContainer>
            <MenuHeaderBar isCollapsed={isCollapsed} />
            {menus.map((item, i) => (
              <Menu
                activateMenu={() => {
                  setActiveMenu(i);
                  setIsCollapsed(false);
                }}
                currentPath={location.pathname}
                item={item}
                key={item.key}
                isMenuActive={activeMenu === i && !isCollapsed}
              />
            ))}
          </MenusContainer>
          <Footer />
        </DesktopSidebar>
      </DesktopMode>
      <MobileMode>
        <MobileSidebar className={isOpen ? 'open' : 'close'}>
          <Hamburger className={isOpen ? 'open' : 'close'} onClick={toggleOpenSidebar}>
            <List />
          </Hamburger>
          <MenusContainer>
            <MenuHeaderBar isCollapsed={isCollapsed} />
            {menus.map((item, i) => (
              <Menu
                activateMenu={() => {
                  setActiveMenu(i);
                  setIsCollapsed(false);
                }}
                currentPath={location.pathname}
                item={item}
                key={item.key}
                isMenuActive={activeMenu === i && !isCollapsed}
              />
            ))}
          </MenusContainer>
          <Footer />
        </MobileSidebar>
      </MobileMode>
    </>
  );
}
