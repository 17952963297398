import { Info } from '@phosphor-icons/react';
import { Tooltip } from 'antd';

export const InfoToolTip = ({ title, size = 12, className = 'ml-1 cursor-pointer' }) => {
  return (
    <Tooltip title={title}>
      <Info size={size} className={className} />
    </Tooltip>
  );
};
