import {
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Input,
  Radio,
  Select,
  Slider,
  Switch,
  TimePicker,
} from 'antd';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { CaretDown, Eye, EyeClosed } from '@phosphor-icons/react';
import {
  CurrencyMaskInput,
  NumberMaskInput,
  PercentageMaskInput,
  PostalCodeMaskInput,
  SSNMaskInput,
} from './MaskInput';
import { cloneElement, isValidElement, useState } from 'react';
import { CommonUtility } from 'utility';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const CustomSlider = styled(Slider)`
  margin: 0px 0px 12px 8px;
`;

const DangerText = styled.span`
  margin-left: 0px !important;
  color: rgba(208, 14, 23, 0.8);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  text-align: right;
  display: block;
  margin-top: 8.5px;
`;

export const CustomInput = styled(Input)``;

const CustomPasswordInput = styled(Input.Password)``;

const CustomTextArea = styled(TextArea)``;

export const CustomSelect = styled(Select)``;

const CustomDatePicker = styled(DatePicker)``;

export const CustomCheckBox = styled(Checkbox)``;

export const MaskedFormInput = styled(Form.Item)``;

export const MuteLabel = styled.label`
  color: #717171;
`;

const Label = ({ label, required, extraLabel }) => {
  return extraLabel ? (
    <div className="d-flex justify-content-between">
      <span>{`${label || ''} ${required ? '*' : ''}`}</span>
      {extraLabel}
    </div>
  ) : (
    <div>
      {isValidElement(label)
        ? cloneElement(label)
        : CommonUtility.isString(label)
        ? label
        : ''}{' '}
      {required ? '*' : ''}
    </div>
  );
};

export function FormTextFormField({
  containerClass = '',
  control,
  defaultValue = '',
  errors,
  extraLabel,
  fieldClass = '',
  hint,
  label,
  name,
  placeholder,
  required,
  tooltip,
  type,
  vertical = false,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <Flex vertical={vertical} className={containerClass || ''}>
          <Label extraLabel={extraLabel} label={label} required={required} />
          <MaskedFormInput
            tooltip={tooltip}
            rules={[{ required, message: errors?.message }]}
            className={`mb-3 mb-md-4 ${fieldClass || ''} ${errors?.message && 'error'}`}
          >
            <CustomInput
              placeholder={placeholder || label}
              value={props.field.value}
              type={type}
              onChange={e => props.field.onChange(e.target.value)}
              {...rest}
            />
            {errors && (
              <DangerText className="danger">{errors?.message || hint}</DangerText>
            )}
          </MaskedFormInput>
        </Flex>
      )}
    />
  );
}

export function FormSwitchFormField({
  containerClass = '',
  control,
  defaultValue = '',
  errors,
  extraLabel,
  fieldClass = '',
  hint,
  label,
  name,
  placeholder,
  required,
  tooltip,
  type,
  vertical = false,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <Flex vertical={vertical} className={containerClass || ''}>
          <Switch
            onChange={checked => props.field.onChange(checked)}
            checked={props.field.value}
            {...rest}
          />
          <Label extraLabel={extraLabel} label={label} required={required} />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
        </Flex>
      )}
    />
  );
}

export function FormPasswordFormField({
  control,
  defaultValue = '',
  errors,
  extraLabel,
  fieldClass = '',
  hint,
  hintLabel,
  label,
  name,
  placeholder,
  required,
  showLabel = true,
  tooltip,
  ...rest
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <MaskedFormInput
          label={
            showLabel ? (
              <Label extraLabel={extraLabel} label={label} required={required} />
            ) : null
          }
          tooltip={tooltip}
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${errors?.message && 'error'} ${fieldClass || ''}`}
        >
          <CustomPasswordInput
            placeholder={placeholder || label}
            value={props.field.value}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
            onBlur={() => setPasswordVisible(false)}
            onChange={e => props.field.onChange(e.target.value)}
            iconRender={visible =>
              visible ? <Eye size={24} /> : <EyeClosed size={24} />
            }
            {...rest}
          />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
          {hintLabel}
        </MaskedFormInput>
      )}
    />
  );
}

export function FormTextAreaFormField({
  control,
  defaultValue,
  errors,
  fieldClass = '',
  height,
  hint,
  label,
  name,
  placeholder,
  required,
  showLabel = true,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <MaskedFormInput
          label={showLabel ? <Label label={label} required={required} /> : null}
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${fieldClass || ''} ${errors?.message && 'error'}`}
        >
          <CustomTextArea
            placeholder={placeholder}
            value={props.field.value}
            style={{ height: height || 100 }}
            onChange={e => props.field.onChange(e.target.value)}
            {...rest}
          />
          {errors && <DangerText>{errors?.message || hint}</DangerText>}
        </MaskedFormInput>
      )}
    />
  );
}

export function FormSelectionField({
  children,
  control,
  defaultValue = '',
  errors,
  extraLabel,
  fieldClass = '',
  hint,
  label,
  mode = 'single',
  name,
  onChange,
  options,
  placeholder = 'Select',
  required,
  showLabel = true,
  showSearch = true,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <MaskedFormInput
          label={
            showLabel ? (
              <Label extraLabel={extraLabel} label={label} required={required} />
            ) : null
          }
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${fieldClass || ''} ${errors?.message && 'error'}`}
        >
          <CustomSelect
            placeholder={placeholder}
            mode={mode}
            showSearch={showSearch}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={data => {
              props.field.onChange(data);
              if (onChange && onChange) {
                onChange(data);
              }
            }}
            suffixIcon={<CaretDown weight="bold" size={16} />}
            options={options}
            value={props.field.value || []}
            {...rest}
          />
          {errors && <DangerText>{errors?.message || hint}</DangerText>}
        </MaskedFormInput>
      )}
    />
  );
}

export const FormCheckBoxField = ({
  containerClass,
  control,
  defaultValue,
  disabled,
  errors,
  extraLabel,
  fieldClass = '',
  fieldProps,
  hint,
  label,
  mb = '',
  name,
  required,
  text,
  vertical,
  width,
  ...rest
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={props => (
      <Flex vertical={vertical} className={containerClass || ''}>
        <Label extraLabel={extraLabel} label={label} required={required} />
        <CustomCheckBox
          className="mr-2"
          name={name}
          checked={props.field.value}
          disabled={disabled}
          defaultValue={false}
          onChange={e => props.field.onChange(e.target.checked)}
          {...rest}
        >
          {text}
        </CustomCheckBox>
        {errors && <DangerText>{errors?.message || hint}</DangerText>}
      </Flex>
    )}
  />
);

export const FormRadioField = ({
  buttonLayout = false,
  containerClass,
  control,
  defaultValue,
  errors,
  extraLabel,
  fieldClass,
  fieldProps,
  hint,
  label,
  name,
  options,
  required,
  text,
  vertical,
  width,
  ...rest
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={props => (
      <Flex vertical={vertical} className={containerClass || ''}>
        <Label extraLabel={extraLabel} label={label} required={required} />
        {buttonLayout ? (
          <Radio.Group
            className="mr-2"
            name={name}
            value={props.field.value}
            label={text}
            onChange={e => props.field.onChange(e.target.value)}
            {...rest}
          >
            {options.map(opt => (
              <Radio.Button value={opt.value}>{opt.label}</Radio.Button>
            ))}
          </Radio.Group>
        ) : (
          <Radio.Group
            className="mr-2"
            options={options}
            name={name}
            value={props.field.value}
            label={text}
            onChange={e => props.field.onChange(e.target.value)}
            {...rest}
          />
        )}
        {errors && <DangerText>{errors?.message || hint}</DangerText>}
      </Flex>
    )}
  />
);

export function FormSliderField({
  containerClass,
  control,
  defaultValue = '',
  errors,
  extraLabel,
  inputExtraClass,
  label,
  max = 100,
  min = 0,
  name,
  required,
  vertical,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <Flex vertical={vertical} className={containerClass || ''}>
          <Label extraLabel={extraLabel} label={label} required={required} />
          <CustomSlider
            value={props.field.value}
            defaultValue={defaultValue}
            min={min}
            max={max}
            onChange={value => props.field.onChange(value)}
            {...rest}
          />
          {errors && <DangerText className="danger">{errors?.message}</DangerText>}
        </Flex>
      )}
    />
  );
}

const ColorPallete = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 5px;
  &.active {
    width: 37px;
    height: 37px;
    border-radius: 4px;
  }
  background-color: ${({ color }) => color};
`;

const PalletBorder = styled.div`
  cursor: pointer;
  &.active {
    border: 2px solid ${({ theme }) => theme.colors.primary};
    padding: 2px;
    border-radius: 5px;
  }
`;

const ColorsContainer = styled.div`
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
`;

export function FormColorField({
  containerClass,
  control,
  defaultValue = '',
  errors,
  extraLabel,
  inputExtraClass,
  label,
  name,
  options,
  required,
  showLabel,
  vertical,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <Flex vertical={vertical} className={containerClass || ''}>
          <Label extraLabel={extraLabel} label={label} required={required} />
          <ColorsContainer {...rest}>
            {options?.map(option => (
              <PalletBorder
                className={option === props.field.value ? 'active' : ''}
                onClick={() => props.field.onChange(option)}
              >
                <ColorPallete
                  className={option === props.field.value ? 'active' : ''}
                  color={option}
                />
              </PalletBorder>
            ))}
          </ColorsContainer>
          {errors && <DangerText className="danger">{errors?.message}</DangerText>}
        </Flex>
      )}
    />
  );
}

export function FormTimeField({
  containerClass,
  control,
  defaultValue = '',
  errors,
  extraLabel,
  hint,
  label,
  name,
  placeholder,
  required,
  type,
  vertical,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <Flex vertical={vertical} className={containerClass || ''}>
          <Label extraLabel={extraLabel} label={label} required={required} />
          <TimePicker
            value={props.field.value}
            onChange={time => props.field.onChange(time)}
            {...rest}
            style={{ width: '100%' }}
          />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
        </Flex>
      )}
    />
  );
}

export function FormDateField({
  control,
  defaultValue = '',
  errors,
  extraLabel,
  hint,
  label,
  name,
  placeholder,
  required,
  type,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <MaskedFormInput
          label={<Label extraLabel={extraLabel} label={label} required={required} />}
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${errors?.message && 'error'}`}
        >
          <CustomDatePicker
            value={props.field.value}
            placeholder={placeholder}
            onChange={dayJS => props.field.onChange(dayJS)}
            style={{ width: '100%' }}
            allowClear={false}
            {...rest}
          />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
        </MaskedFormInput>
      )}
    />
  );
}

export function FormDateTimeField({
  containerClass,
  control,
  defaultValue = '',
  errors,
  extraLabel,
  hint,
  label,
  name,
  placeholder,
  required,
  showTime = true,
  type,
  vertical,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <Flex vertical={vertical} className={containerClass || ''}>
          <Label extraLabel={extraLabel} label={label} required={required} />
          <RangePicker
            showTime={showTime}
            value={props.field.value}
            defaultValue={defaultValue}
            onChange={dayJS => props.field.onChange(dayJS)}
            allowClear={false}
            {...rest}
          />
          {errors && <DangerText className="danger">{errors?.message}</DangerText>}
        </Flex>
      )}
    />
  );
}

export function FormRangeTimeField({
  containerClass,
  control,
  defaultValue = '',
  errors,
  extraLabel,
  hint,
  label,
  name,
  placeholder,
  required,
  type,
  vertical,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => (
        <Flex vertical={vertical} className={containerClass || ''}>
          <Label extraLabel={extraLabel} label={label} required={required} />
          <TimePicker.RangePicker
            value={props.field.value}
            onChange={time => props.field.onChange(time)}
            allowClear={false}
            style={{ width: '100%' }}
            {...rest}
          />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
        </Flex>
      )}
    />
  );
}

export function MaskedNumberFormField({
  control,
  defaultValue = null,
  errors,
  extraLabel,
  hint,
  inputExtraClass,
  label,
  maskOptions,
  maxValue,
  minValue,
  name,
  placeholder,
  required,
  vertical,
  containerClass,
  tooltip,
  fieldClass,
  ...rest
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={props => (
        <Flex vertical={vertical} className={containerClass || ''}>
          <Label extraLabel={extraLabel} label={label} required={required} />
          <MaskedFormInput
            tooltip={tooltip}
            rules={[{ required, message: errors?.message }]}
            className={`mb-3 mb-md-4 ${fieldClass || ''} ${errors?.message && 'error'}`}
          >
            <NumberMaskInput
              value={props.field.value}
              onChange={value => props.field.onChange(value)}
              maskOptions={maskOptions}
              placeholder={placeholder || label}
              minValue={minValue}
              maxValue={maxValue}
              render={(ref, rprops) => (
                <CustomInput value={props.field.value} ref={ref} {...rprops} />
              )}
              {...rest}
            />
            {errors && (
              <DangerText className="danger">{errors?.message || hint}</DangerText>
            )}
          </MaskedFormInput>
        </Flex>
      )}
    />
  );
}

export function MaskedSSNFormField({
  control,
  defaultValue = null,
  errors,
  hint,
  label,
  name,
  placeholder,
  required,
  ...rest
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={props => (
        <MaskedFormInput
          label={<Label label={label} required={required} />}
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${errors?.message && 'error'}`}
        >
          <SSNMaskInput
            value={props.field.value}
            onChange={value => props.field.onChange(value)}
            placeholder={placeholder || label}
            {...rest}
          />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
        </MaskedFormInput>
      )}
    />
  );
}

export function MaskedPostalCodeFormField({
  control,
  defaultValue = null,
  errors,
  hint,
  label,
  name,
  placeholder,
  required,
  ...rest
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={props => (
        <MaskedFormInput
          label={<Label label={label} required={required} />}
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${errors?.message && 'error'}`}
        >
          <PostalCodeMaskInput
            value={props.field.value}
            onChange={value => props.field.onChange(value)}
            placeholder={placeholder || label}
            {...rest}
          />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
        </MaskedFormInput>
      )}
    />
  );
}

export function MaskedCurrencyFormField({
  control,
  defaultValue = null,
  errors,
  extraLabel,
  hint,
  label,
  maskOptions,
  name,
  placeholder,
  required,
  ...rest
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={props => (
        <MaskedFormInput
          label={<Label extraLabel={extraLabel} label={label} required={required} />}
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${errors?.message && 'error'}`}
        >
          <CurrencyMaskInput
            value={props.field.value}
            onChange={value => props.field.onChange(value)}
            maskOptions={maskOptions}
            placeholder={placeholder || label}
            {...rest}
          />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
        </MaskedFormInput>
      )}
    />
  );
}

export function MaskedPercentageFormField({
  control,
  defaultValue,
  errors,
  extraLabel,
  hint,
  label,
  maskOptions,
  name,
  placeholder,
  required,
  ...rest
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={props => (
        <MaskedFormInput
          label={<Label extraLabel={extraLabel} label={label} required={required} />}
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${errors?.message && 'error'}`}
        >
          <PercentageMaskInput
            value={props.field.value}
            onChange={value => props.field.onChange(value)}
            maskOptions={maskOptions}
            placeholder={placeholder || label}
            {...rest}
          />
          {errors && (
            <DangerText className="danger">{errors?.message || hint}</DangerText>
          )}
        </MaskedFormInput>
      )}
    />
  );
}
