import React, { useEffect, useRef, useState } from 'react';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
  Background,
  BackgroundVariant,
  ReactFlowProvider,
} from '@xyflow/react';
import styled from 'styled-components';
import { CustomEdge, InheritanceNode, RelationNode } from './PersonNode';
import { ExpandCollapse, MapGuide, SaveBar } from './common';
import {
  generateParentEdge,
  generatePosition,
  getValidEdge,
} from './PersonNode/EdgeUtility';
import '@xyflow/react/dist/style.css';
import './index.css';
import { CommonUtility, genders } from 'utility';
import { useTreeContext } from 'hooks/treeContext';
import { EditSidebar } from './edit-sidebar';
import { useShares } from 'hooks';
import { useMehramDetector } from 'hooks/mehram';
import { useTreeSaver } from 'hooks/tree';
import { DesktopMode, MobileMode, useDesktopMediaQuery } from 'layouts';

const defaultViewport = { x: 100, y: 100, zoom: 1 };

const TreeContainer = styled.div`
  width: 100%;
  height: ${({ reduceHeight }) => `calc(100vh - ${reduceHeight}px)`};
  min-height: 300px;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  transition: height 0.2s;
`;

const snapGrid = [20, 20];

const edgeTypes = {
  custom: CustomEdge,
};

const defaultEdgeOptions = {
  type: 'turbo',
};

export const FamilyTree = ({
  isInheritance = false,
  mehramDetector = false,
  reduceHeight = 50,
  setIsSolutionValid,
}) => {
  const {
    setActiveEdge,
    setShares,
    setShareData,
    setIslamicRelations,
    selectedNode,
    setSelectedNode,
  } = useTreeContext();

  const [heightReducer, setHeightReducer] = useState(reduceHeight);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodeTypes, setNodeTypes] = useState({
    person: InheritanceNode,
  });

  const {
    nodes: DBnodes,
    edges: DBedges,
    selectedNode: DBselectedNode,
    saving,
  } = useTreeSaver(nodes, edges, selectedNode);

  const { personShare, isSolutionValid } = useShares(
    selectedNode,
    nodes,
    edges,
    isInheritance,
  );

  const { islamicRelation } = useMehramDetector(
    selectedNode,
    edges,
    nodes,
    mehramDetector,
  );

  useEffect(() => {
    if (setIsSolutionValid) {
      setIsSolutionValid(isSolutionValid);
    }
  }, [isSolutionValid]);

  const isDesktop = useDesktopMediaQuery();

  useEffect(() => {
    if (isDesktop) {
      setHeightReducer(reduceHeight);
    }
  }, [reduceHeight, isDesktop]);

  useEffect(() => {
    if (!mehramDetector) return;

    setNodeTypes({
      person: RelationNode,
    });

    setIslamicRelations(islamicRelation);
  }, [islamicRelation, mehramDetector]);

  useEffect(() => {
    if (!isInheritance) return;

    setNodeTypes({
      person: InheritanceNode,
    });

    const hasResidue = Object.values(personShare).some(share => share.residuary);
    let totalShares = 0;
    let totalRatio = 0;
    let spouseShare = 0;
    Object.values(personShare).forEach(share => {
      if (share.share && !share.spouse) {
        totalShares += share.share;
      }

      if (share.share && share.spouse) {
        spouseShare += share.share;
      }
    });

    Object.values(personShare).forEach(share => {
      if (share.ratio) {
        totalRatio += share.ratio;
      }
    });

    setShareData(data => ({
      ...data,
      totalShares,
      hasResidue,
      totalRatio,
      spouseShare,
    }));

    setShares(personShare);
  }, [personShare, isInheritance]);

  const onNodeChange = (id, nodeData) => {
    setNodes(oldNodes =>
      oldNodes.map(oldNode => {
        if (oldNode.id === id) {
          return {
            ...oldNode,
            data: {
              ...oldNode.data,
              ...nodeData,
            },
          };
        }
        return oldNode;
      }),
    );
  };

  const onChange = (id, key, value) => {
    setNodes(oldNodes =>
      oldNodes.map(oldNode => {
        if (oldNode.id === id) {
          return {
            ...oldNode,
            data: {
              ...oldNode.data,
              [key]: value,
            },
          };
        }
        return oldNode;
      }),
    );
  };

  const removeNode = id => {
    setNodes(oldNodes => oldNodes.filter(x => x.id !== id));
    setEdges(oldEdges => oldEdges.filter(x => x.source !== id && x.target !== id));
  };

  const addNewEdge = edge => {
    setEdges(eds =>
      addEdge(
        {
          ...edge,
          data: {
            ...edge.data,
            addCommonChild,
            onEdgeChange,
            toggleDivorce,
            toggleFoster,
            removeEdge,
          },
        },
        eds,
      ),
    );
  };

  const getRandomXPosition = (positionX, index) => {
    if (index % 2 === 0) {
      return positionX - (index || 1) * 100;
    }
    return positionX + index * 100;
  };

  const addNode = (id, newNode, newEdge = null, newNodePos = null) => {
    setNodes(oldNodes => {
      if (CommonUtility.isValidArray(newNode)) {
        const nodes = [...oldNodes];
        const position = generatePosition(
          id,
          nodes,
          newEdge[0].data.relation,
          newNodePos,
        );

        newNode.forEach((nNode, index) => {
          nodes.push({
            ...nNode,
            data: {
              ...nNode.data,
              // custome functions here
              onChange,
              removeNode,
              addNewEdge,
              addNode,
            },
            position: {
              x: getRandomXPosition(position.x, index),
              y: position.y,
            },
          });
        });
        return nodes;
      } else {
        return [
          ...oldNodes,
          {
            ...newNode,
            data: {
              ...newNode.data,
              // custome functions here
              onChange,
              addNewEdge,
              removeNode,
              addNode,
            },
            position: generatePosition(id, oldNodes, newEdge.data.relation, newNodePos),
          },
        ];
      }
    });

    if (newEdge) {
      if (CommonUtility.isValidArray(newEdge)) {
        setEdges(oldEdges => [
          ...oldEdges,
          ...newEdge.map(edge => ({
            ...edge,
            data: {
              ...edge.data,
              addCommonChild,
              onEdgeChange,
              toggleFoster,
              toggleDivorce,
              removeEdge,
            },
          })),
        ]);
      } else {
        setEdges(oldEdges => [
          ...oldEdges,
          {
            ...newEdge,
            data: {
              ...newEdge.data,
              addCommonChild,
              onEdgeChange,
              toggleFoster,
              toggleDivorce,
              removeEdge,
            },
          },
        ]);
      }
    }
  };

  const addCommonChild = (relation, children, newNodePos) => {
    const fatherId = relation.source;
    const motherId = relation.target;
    let edges = [];

    for (const child of children) {
      edges.push(generateParentEdge(fatherId, child.id, child.data.gender, genders.male));
      edges.push(
        generateParentEdge(motherId, child.id, child.data.gender, genders.female),
      );
    }

    addNode(fatherId, children, edges, newNodePos);
  };

  const onEdgeChange = (id, key, value) => {
    setEdges(oldEdged =>
      oldEdged.map(oldEdge => {
        if (oldEdge.id === id) {
          return {
            ...oldEdge,
            data: {
              ...oldEdge.data,
              [key]: value,
            },
          };
        }
        return oldEdge;
      }),
    );
  };

  const removeEdge = id => {
    setEdges(oldEdges => oldEdges.filter(x => x.id !== id));
  };

  const toggleDivorce = (id, currectDivorced) => {
    setEdges(oldEdged =>
      oldEdged.map(oldEdge => {
        if (oldEdge.id === id) {
          return {
            ...oldEdge,
            animated: !currectDivorced,
            data: {
              ...oldEdge.data,
              divorced: !currectDivorced,
            },
          };
        }
        return oldEdge;
      }),
    );
  };

  const toggleFoster = (id, currectDivorced) => {
    setEdges(oldEdged =>
      oldEdged.map(oldEdge => {
        if (oldEdge.id === id) {
          return {
            ...oldEdge,
            animated: !currectDivorced,
            data: {
              ...oldEdge.data,
              foster: !currectDivorced,
            },
          };
        }
        return oldEdge;
      }),
    );
  };

  useEffect(() => {
    if (!CommonUtility.isValidArray(DBnodes)) return;

    const nodes = DBnodes.map(node => ({
      ...node,
      data: {
        ...node.data,
        // custome functions here
        onChange,
        addNewEdge,
        removeNode,
        addNode,
      },
    }));
    setNodes(nodes);
  }, [DBnodes]);

  useEffect(() => {
    if (!CommonUtility.isValidArray(DBedges)) return;
    const edges = DBedges.map(edges => ({
      ...edges,
      data: {
        ...edges.data,
        // custome functions here
        addCommonChild,
        onEdgeChange,
        toggleFoster,
        toggleDivorce,
        removeEdge,
      },
    }));
    setEdges(edges);
  }, [DBedges]);

  useEffect(() => {
    if (!DBselectedNode) return;
    setSelectedNode(DBselectedNode);
  }, [DBselectedNode]);

  const onConnect = params => {
    const edge = getValidEdge(params, nodes, edges);
    if (edge) {
      setEdges(eds =>
        addEdge(
          {
            ...edge,
            data: {
              ...edge.data,
              addCommonChild,
              onEdgeChange,
              toggleFoster,
              toggleDivorce,
              removeEdge,
            },
          },
          eds,
        ),
      );
    }
  };

  const removeEdgeFocus = () => {
    setActiveEdge(null);
  };

  const addEdgeFocus = (e, edge) => {
    setActiveEdge(edge.id);
  };

  const myRef = useRef(null);

  const scrollToTop = () => {
    if (myRef?.current) {
      const yOffset = 0;
      const elementFromTop = myRef.current.getBoundingClientRect().top;
      const y = elementFromTop + window.scrollY + yOffset;
      window.scrollTo({ top: y });
    }
  };

  return (
    <TreeContainer ref={myRef} reduceHeight={heightReducer}>
      <ReactFlowProvider>
        <ReactFlow
          attributionPosition="bottom-left"
          defaultViewport={defaultViewport}
          edgeTypes={edgeTypes}
          edges={edges}
          nodeTypes={nodeTypes}
          nodes={nodes}
          onConnect={onConnect}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
          snapGrid={snapGrid}
          paneClickDistance={2}
          snapToGrid
          defaultEdgeOptions={defaultEdgeOptions}
          onEdgeClick={addEdgeFocus}
          onPaneClick={removeEdgeFocus}
          onNodeClick={removeEdgeFocus}
          onNodeDragStart={removeEdgeFocus}
          fitView
          proOptions={{
            hideAttribution: true,
          }}
          zoomOnDoubleClick={false}
        >
          <MobileMode>
            <ExpandCollapse
              defaultHeightReducer={reduceHeight}
              setHeightReduce={setHeightReducer}
              scrollToTop={scrollToTop}
            />
          </MobileMode>
          <DesktopMode>
            <MapGuide />
          </DesktopMode>
          <DesktopMode>
            <SaveBar saving={saving} />
          </DesktopMode>
          <DesktopMode>
            <MiniMap pannable onClick={removeEdgeFocus} />
          </DesktopMode>
          <Controls
            showInteractive={false}
            showFitView={isDesktop}
            onZoomIn={removeEdgeFocus}
            onZoomOut={removeEdgeFocus}
          />
          <Background variant={BackgroundVariant.Lines} gap={95} />
        </ReactFlow>
        <EditSidebar
          isInheritance={isInheritance}
          removeNode={removeNode}
          onNodeChange={onNodeChange}
        />
      </ReactFlowProvider>
    </TreeContainer>
  );
};
