export const themeColors = {
  darkGreen: '#032400',
  white: 'white',
  red: '#FF2400',
  grey: '#ACADB6',

  primary: '#12100E',
  background: '#FCFCFC',
  secondaryDark: '#4D4D60',
  secondaryLight: '#2C666E',
  light: '#95BF48',
};
