import { createContext, useContext, useState } from 'react';

const TreeContext = createContext();

export function TreeContextProvider({ children }) {
  const [activeEdge, setActiveEdge] = useState(null);
  const [activeNode, setActiveNode] = useState(null);
  const [shares, setShares] = useState({});
  const [shareData, setShareData] = useState({});
  const [islamicRelations, setIslamicRelations] = useState({});
  const [selectedNode, setSelectedNode] = useState('1');

  return (
    <TreeContext.Provider
      value={{
        activeEdge,
        setActiveEdge,
        activeNode,
        setActiveNode,
        shares,
        setShareData,
        setShares,
        shareData,
        setIslamicRelations,
        islamicRelations,
        selectedNode,
        setSelectedNode,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
}

export const useTreeContext = () => useContext(TreeContext);
